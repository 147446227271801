<template v-if="isParentVisible">
  <div
    class="border border-gray-600 rounded-md shadow-standard my-4"
    :class="depth > 0 && 'bg-trans'"
    :style="indent"
    v-observe-visibility="{ callback: visibilityChanged, once: true }"
  >
<!--     <pre>-->
<!--      showChildren: {{showChildren}}-->
<!--      type: {{organisation.type}}-->
<!--      isAggregateMode: {{isAggregateMode}}-->
<!--      name: {{organisation.name}}-->
<!--      organisation.type != 'VBU': {{organisation.type != 'VBU'}}-->
<!--      showChildren && (!isAggregateMode || (isAggregateMode && organisation.type != 'VBU')) {{showChildren && (!isAggregateMode || (isAggregateMode && organisation.type != 'VBU'))}}-->
<!--    </pre>-->
    <button
      class="flex w-full justify-between items-center rounded-t-md bg-bgLighter border-b border-gray-700 px-6 py-3 text-gray-300 card--title cursor-default"
      :class="{
        'hover:bg-bgLight cursor-pointer': organisation?.children?.length && !isAggregateMode,
        'bg-blue-500 cursor-pointer': isAggregateMode && (organisation.type == 'Portfolio'),
        'bg-yellow-600 cursor-pointer': isAggregateMode && (organisation.type == 'Sub-Portfolio'),
        'bg-green-500 cursor-pointer': isAggregateMode && (organisation.type == 'Group')
      }"
      @click="showChildren = !showChildren"
    >
      <h4 class="font-normal item--name ml-4" :class="organisation.type">
        <span :class="{'text-white': isAggregateMode}">{{ organisation.name }}</span>
        <span> - </span>
        <div class="inline-block">
          <h4 class="font-normal text-sm inline" :class="isAggregateMode ? 'text-white' : 'text-gray-500'">
            Number of recorded devices:
          </h4>
          <p class="inline" :class="isAggregateMode ? 'text-white' : 'text-gray-400'" v-if="!isAggregateMode">{{ organisation.actual_devices }}</p>
          <p class="inline" :class="isAggregateMode ? 'text-white' : 'text-gray-400'" v-else>{{ organisation.actual_devices_aggregate }}</p>
        </div>
      </h4>

      <div class="ml-auto mr-4">
        <div class="inline-block mr-8" v-if="reportsTo">
          <h4 class="font-normal text-sm inline mr-2" :class="isAggregateMode ? 'text-white' : 'text-gray-500'">
            Reports to:
          </h4>
          <p class="inline" :class="isAggregateMode ? 'text-white' : 'text-gray-400'">{{ reportsTo.name }}</p>
        </div>
      </div>

      <font-awesome-icon
        v-if="this.organisation?.children?.length"
        :icon="['fa', 'chevron-down']"
        class="transform transition-transform mt-0.5"
        :class="{'rotate-180': !this.showChildren, 'text-white': isAggregateMode}"
      />
    </button>
    <!-- System widget -->
    <div class="grid grid-cols-3 gap-4 ml-4 mr-2 mt-4" v-if="isVisible">
      <CrowdstrikeWidget
        class="border border-gray-700 rounded-lg overflow-hidden"
        :org="organisation"
      />
      <NetsparkerWidget
        class="border border-gray-700 rounded-lg overflow-hidden"
        :org="organisation"
      />
      <SpyCloudWidget
        class="border border-gray-700 rounded-lg overflow-hidden"
        :org="organisation"
      />
      <ProofpointWidget
        class="border border-gray-700 rounded-lg overflow-hidden"
        :org="organisation"
      />
      <KnowBe4Widget
        class="border border-gray-700 rounded-lg overflow-hidden"
        :org="organisation"
      />
      <CiscoWidget
        class="border border-gray-700 rounded-lg overflow-hidden"
        :org="organisation"
      />
      <Rapid7Widget
        class="border border-gray-700 rounded-lg overflow-hidden"
        :org="organisation"
      />
      <AzureWidget
        class="border border-gray-700 rounded-lg overflow-hidden"
        :org="organisation"
      />
      <WorkdayWidget
        class="border border-gray-700 rounded-lg overflow-hidden"
        :org="organisation"
      />
    </div>
    <!-- EO SUMMARY -->
    <div class="p-2">
      <!-- Intro and itself info -->
      <!-- Recursive down -->
      <transition name="fade">
<!--        <div v-show="showChildren && (!isAggregateMode || (isAggregateMode && organisation.type != 'VBU'))">-->
        <div v-show="showChildren">
          <DashboardObject
            v-for="node in organisation.children"
            :key="node.org_id"
            :organisation="node"
            :depth="depth + 1"
            :isParentVisible="isVisible"
            v-show="!isAggregateMode || (isAggregateMode && node.type != 'VBU')"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import CrowdstrikeWidget from './CrowdstrikeWidget.vue';
import NetsparkerWidget from './NetsparkerWidget.vue';
import SpyCloudWidget from '@/components/SpyCloudWidget';
import ProofpointWidget from '@/components/ProofpointWidget';
import KnowBe4Widget from '@/components/KnowBe4Widget';
import CiscoWidget from '@/components/CiscoWidget';
import Rapid7Widget from '@/components/Rapid7Widget';
import AzureWidget from '@/components/AzureWidget';
import WorkdayWidget from '@/components/WorkdayWidget';

export default {
  components: {
    CrowdstrikeWidget,
    NetsparkerWidget,
    SpyCloudWidget,
    ProofpointWidget,
    KnowBe4Widget,
    CiscoWidget,
    Rapid7Widget,
    AzureWidget,
    WorkdayWidget,
  },
  name: 'DashboardObject',
  props: {
    organisation: {
      type: Object,
      required: true,
    },
    depth: {
      type: Number,
      default: 0,
    },
    isParentVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showChildren: true,
      isVisible: false,
    };
  },
  methods: {
    visibilityChanged(isVisible, entry) {
      this.isVisible = true;
      // this.isVisible = isVisible;
    },
  },
  computed: {
    indent() {
      return {
        marginLeft: `${this.depth / 10}rem`,
      };
    },
    reportsTo() {
      if (this.organisation.reports_to) {
        return this.$store.getters['structure/GET_ORGANISATION'](
          this.organisation.reports_to
        );
      } else {
        return false;
      }
    },
    isAggregateMode() {
      return this.$store.state.structure.isAggregateMode;
    }
  },
};
</script>

<style lang="scss" scoped>
.shadow-standard {
  box-shadow: -5px 5px 8px -3px rgba(0, 0, 0, 0.35);
}

.bg-trans {
  background-color: rgba(255, 255, 255, 0.025);
}

.item--name {
  @apply relative;

  &::after {
    @apply absolute top-1/2 transform -translate-y-1/2 w-2 h-2 rounded-full;

    content: '';
    left: -1.5rem;
  }

  &.vbu::after {
    @apply bg-gray-400;
  }

  &.group::after {
    @apply bg-green-500;
  }

  &.portfolio::after {
    @apply bg-blue-500;
  }
}
</style>

import axios from 'axios';
import { changeSubscriptionToken } from '../../../src/apollo';

const defaultAuthStore = () => ({
  jwt: {
    jwt_token: null,
    jwt_token_expiry: null,
    refresh_token: null,
  },
});

const state = defaultAuthStore();

const getters = {
  jwt: (state) => state.jwt,
};

const actions = {
  updateJwt({ commit }, content) {
    commit('setJwt', content);
  },
  refreshToken({ dispatch }) {
    return new Promise(async (resolve, reject) => {
      try {
        const refreshToken = await axios.post(
          'https://id.redsmart.app/auth/refresh-token',
          { application: 'enexo' },
          {
            withCredentials: true,
          }
        );
        if (refreshToken.data.jwt_token) {
          changeSubscriptionToken(refreshToken.data.jwt_token);
          dispatch('updateJwt', refreshToken.data);
        }
        resolve();
      } catch (error) {
        console.log(error);
        reject();
      }
    });
  },
};
// mutations
const mutations = {
  RESET_STORE: (store) => {
    Object.assign(store, defaultAuthStore());
  },
  setJwt(state, content) {
    state.jwt = content;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

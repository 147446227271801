import { apolloClient as apollo } from '@/apollo';
import { currentMonth } from '@/utils/time';

const state = {
  data: {},
};

const getters = {};

const mutations = {
  RESET_STORE: (store) => {
    Object.assign(store, { data: [] });
  },
  SET_DATA: (state, { data }) => {
    data.forEach((dataEl) => {
      const dataElementPretty = {
        users_count: dataEl.azure_count.aggregate.count,
        mfa_count: dataEl.mfa_count.aggregate.count,
        mfa_incapable: dataEl.mfa_incapable.aggregate.count,
      };

      state.data[dataEl.org_id] = dataElementPretty;
    });
  },
};

const actions = {
  getAllData({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { _organisations },
        } = await apollo.query({
          query: require('@/graphql/azure/get_all_azure.gql'),
        });

        commit('SET_DATA', { data: _organisations });

        resolve();
      } catch (e) {
        console.log(e);
        reject();
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import { apolloClient as apollo } from '@/apollo';
import { currentMonth, prevMonth, prevOfPrevMonth } from '@/utils/time';

const state = {
  data: {},
};

const getters = {};

const mutations = {
  RESET_STORE: (store) => {
    Object.assign(store, { data: [] });
  },
  SET_DATA: (state, { data }) => {
    data.forEach((dataEl) => {
      const dataElementPretty = {
        curr_month_blocks:
          dataEl.cisco.curr_month.aggregate.sum.requestsblocked,
        prev_month_blocks:
          dataEl.cisco.prev_month.aggregate.sum.requestsblocked,
        prevofprev_month_blocks:
          dataEl.cisco.prevofprev_month.aggregate.sum.requestsblocked,
        curr_month_requests: dataEl.cisco.curr_month.aggregate.sum.requests,
        prev_month_requests: dataEl.cisco.prev_month.aggregate.sum.requests,
        prevofprev_requests:
          dataEl.cisco.prevofprev_month.aggregate.sum.requests,
        domains: dataEl.cisco.curr_month.aggregate.sum.domains,
      };

      if (!Object.prototype.hasOwnProperty.call(state.data, dataEl.org_id)) {
        state.data[dataEl.org_id] = [dataElementPretty];
      } else {
        state.data[dataEl.org_id].push(dataElementPretty);
      }
    });
  },
  SET_ACTIVE(state, { organisations }) {
    organisations.forEach((org) => {
      state.isActive[org.org_id] = org.active;
    });
  },
};

const actions = {
  getAllData({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { _organisations },
        } = await apollo.query({
          query: require('@/graphql/cisco/get_all_data.gql'),
          variables: {
            currentMonth,
            prevMonth,
            prevOfPrevMonth,
          },
        });

        commit('SET_DATA', { data: _organisations });

        resolve();
      } catch (e) {
        console.log(e);
        reject();
      }
    });
  },
  // getHeatmapData({ commit }) {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const {
  //         data: { _spycloud_active },
  //       } = await apollo.query({
  //         query: require('@/graphql/spycloud/get_heatmap_data.gql'),
  //       });
  //       commit('SET_ACTIVE', { organisations: _spycloud_active });

  //       resolve();
  //     } catch (e) {
  //       console.log(e);
  //       reject();
  //     }
  //   });
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

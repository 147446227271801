<template>
  <router-link
    class="card flex flex-col cursor-auto"
    :to="blocksData ? `/#` : '#'"
  >
    <div
      class="flex justify-between h-12 items-center bg-bgLighter border-b border-gray-700 px-6 py-3 text-gray-300 card--title"
      :class="blocksData && 'clickable'"
    >
      <div>
        <img src="@/assets/workday_logo.png" class="h-6 inline-block" />
        <BaseTooltip class="ml-2 inline-block" :info="'Late Leavers Tracking'">
          <font-awesome-icon
            class="text-gray-500"
            :icon="['fa', 'info-circle']"
          />
        </BaseTooltip>
      </div>
      <font-awesome-icon
        v-if="blocksData"
        class="text-gray-700 text-xl transform -rotate-45 icon transition-colors"
        :icon="['fa', 'arrow-right']"
      />
    </div>
    <div
      class="w-full h-full flex items-center border-r border-gray-700"
      v-if="blocksData"
    >
      <div class="flex flex-col items-center px-6 py-4 w-full">
        <div class="flex items-center">
          <!-- <font-awesome-icon
            class="text-xl mr-2"
            :class="
              orgData.prev_month_blocks > orgData.prevofprev_month_blocks
                ? 'text-red-500'
                : 'text-green-500'
            "
            :icon="[
              'fa',
              `arrow-alt-circle-${
                orgData.prev_month_blocks > orgData.prevofprev_month_blocks
                  ? 'up'
                  : 'down'
              }`,
            ]"
          /> -->
          <font-awesome-icon
            class="text-xl mr-2 text-green-500"
            :icon="['fa', 'business-time']"
          />
          <h2 class="text-4xl">
            <!-- {{
              orgData.assets
                ? formatBigNum(Math.round(orgData.risk_score / orgData.assets))
                : 0
            }} -->
            0
          </h2>
        </div>
        <p class="text-sm text-gray-500 text-center">
          late leavers for<br />
          previous quarter
        </p>
      </div>
      <div class="self-stretch border-l border-gray-700"></div>
      <div class="flex flex-col items-center justify-center h-full px-6 w-full">
        <div class="flex items-center">
          <font-awesome-icon
            class="text-xl mr-2 text-green-500"
            :icon="['fa', 'user-clock']"
          />
          <h2 class="text-4xl">0</h2>
        </div>
        <p class="text-sm text-gray-500 text-center">
          current late <br />
          leavers
        </p>
      </div>
    </div>
    <div class="flex items-center justify-center h-full" v-else>
      <p class="py-8 opacity-50">No rapid7 account connected.</p>
    </div>
  </router-link>
</template>

<script>
import BaseTooltip from '@/components/Base/BaseTooltip';
import { formatBigNum } from '@/utils/utils';

export default {
  components: {
    BaseTooltip,
  },
  props: {
    org: {
      type: Object,
      required: true,
    },
  },
  computed: {
    orgData() {
      let dataObj = {
        assets: 0,
        risk_score: 0,
      };

      if (this.blocksData) {
        // Aggregates data in case there's more than one email_org<=>org_id connections
        dataObj.assets = this.blocksData.assets;
        dataObj.risk_score = this.blocksData.risk_score;
      }

      return dataObj;
    },
    blocksData() {
      return this.$store.state.rapid7.data[this.org.org_id] || null;
    },
  },
  methods: {
    formatBigNum,
  },
};
</script>

<style lang="scss" scoped>
.card {
  &--title {
    @apply transition-colors duration-300 relative;
  }

  &:hover {
    .card--title.clickable {
      background-color: rgba(52, 52, 62, 1);

      .icon {
        @apply text-gray-400;
      }
    }
  }
}
</style>

<template>
  <div>
    <BackButton />

    <div class="flex justify-between">
      <div>
        <h4 class="text-lg mr-auto">{{ org.name }}</h4>
          <p class="mb-6">
            Crowdstrike detections over the preceding 6 months
          </p>
      </div>
      <div class="text-center my-2">
        <img src="@/assets/crowdstrike_logo.svg" class="h-8 inline-block" />
      </div>
    </div>

    <div v-if="!isLoading">
      <CrowdstrikeLastMonthsChart :chartData="chartData" />
    </div>
    <div class="w-10 h-10 mx-auto my-4" v-else>
      <SpinnerBase />
    </div>
  </div>
</template>

<script>
import CrowdstrikeLastMonthsChart from '@/components/CrowdstrikeLastMonthsChart';
import SpinnerBase from '@/components/Base/SpinnerBase';
import BackButton from '@/components/Base/BackButton';

export default {
  components: {
    BackButton,
    CrowdstrikeLastMonthsChart,
    SpinnerBase,
  },
  data() {
    return {
      isLoading: true,
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Low',
            data: [],
            backgroundColor: 'rgba(59, 130, 246, 0.7)',
            // borderWidth: 1,
            // borderColor: 'rgba(59, 130, 246, 1)',
          },
          {
            label: 'Medium',
            data: [],
            backgroundColor: 'rgba(139, 92, 246, 0.7)',
            // borderWidth: 1,
            // borderColor: 'rgba(139, 92, 246, 1)',
          },
          {
            label: 'High',
            data: [],
            backgroundColor: 'rgba(245, 158, 11, 0.7)',
            // borderWidth: 1,
            // borderColor: 'rgba(245, 158, 11, 1)',
          },
          {
            label: 'Critical',
            data: [],
            backgroundColor: 'rgba(239, 68, 68, 0.57',
            // borderWidth: 1,
            // borderColor: 'rgba(239, 68, 68, 1)',
          },
        ],
      },
    };
  },
  computed: {
    orgId() {
      return this.$route.params.id;
    },
    org() {
      return this.$store.getters['structure/GET_ORGANISATION'](this.orgId);
    },
  },
  async mounted() {
    // get data and populate

    const monthsData = await this.$store.dispatch(
      'crowdstrike/getLatestSixMonths',
      {
        cids: this.$store.getters['crowdstrike/GET_CID'](this.org.org_id),
      }
    );

    const reversedData = [...monthsData].reverse();

    reversedData.forEach((monthData) => {
      const month = new Date(monthData.month_start)
        .toLocaleString('en-GB', { month: 'long' })
        .toUpperCase();
      if (!this.chartData.labels.find((el) => el == month)) {
        this.chartData.labels.push(month);
      }

      this.chartData.datasets[0].data.push(monthData.low);
      this.chartData.datasets[1].data.push(monthData.medium);
      this.chartData.datasets[2].data.push(monthData.high);
      this.chartData.datasets[3].data.push(monthData.critical);
    });

    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <BackButton />

    <div v-if="!$apollo.queries._organisations.loading">
      <div class="flex justify-between">
        <div>
          <h4 class="text-lg mr-auto">{{ org.name }}</h4>
          <p class="mb-6">
            Azure detail page
          </p>
        </div>
        <div class="text-center my-2">
          <img src="@/assets/azure_logo.png" class="h-10 inline-block" />
        </div>
      </div>

      <div class="relative py-4">
        <div class="tab-titles flex">
          <div
            class="tab-title-item bg-purple-500"
            @click="showTab('with_azure')"
          >
            Emails with Azure data
          </div>
          <div
            class="tab-title-item"
            @click="showTab('without_azure')"
          >
            Emails without Azure data
          </div>
        </div>
        <div class="tab-contents border border-purple-500 p-4">
          <div class="tab-content-item visible" id="with_azure">
            <input
              class="outline-none text-black border-gray-700 rounded-lg px-4 py-2 mb-2"
              type="search"
              placeholder="Search ..."
              v-model="searchEmailsWithAzure"
            >
            <div class="rounded-lg overflow-hidden border-b border-gray-50 border-opacity-20">
              <table class="w-full">
                <thead>
                <tr class="bg-purple-800 border-r border-l border-purple-800">
                  <th class="px-4 py-3 border-r border-l border-gray-50 border-opacity-20">User Principal Name</th>
                  <th class="px-4 py-3 border-r border-l border-gray-50 border-opacity-20">User Display Name</th>
                  <th class="px-4 py-3 border-r border-l border-gray-50 border-opacity-20">SSPR Registered</th>
                  <th class="px-4 py-3 border-r border-l border-gray-50 border-opacity-20">SSPR Enabled</th>
                  <th class="px-4 py-3 border-r border-l border-gray-50 border-opacity-20">MFA Registered</th>
                  <th class="px-4 py-3 border-r border-l border-gray-50 border-opacity-20">MFA Capable</th>
                  <th class="px-4 py-3 border-r border-l border-gray-50 border-opacity-20">Passwordless Capable</th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="(item, index) in emailsWithAzureSearched"
                  :key="index"
                  class="text-center bg-opacity-10"
                  :class="{'bg-gray-50': ((index % 2) == 1)}"
                >
                  <td class="py-2 border-r border-l border-gray-50 border-opacity-20">{{item.azure_mfa.user_principal_name}}</td>
                  <td class="py-2 border-r border-l border-gray-50 border-opacity-20">{{item.azure_mfa.user_display_name}}</td>
                  <td
                    class="py-2 border-r border-l border-gray-50 border-opacity-20"
                    :class="item.azure_mfa.is_sspr_registered ? 'text-green-500' : 'text-red-500'"
                  >
                    {{item.azure_mfa.is_sspr_registered}}
                  </td>
                  <td
                    class="py-2 border-r border-l border-gray-50 border-opacity-20"
                    :class="item.azure_mfa.is_sspr_enabled ? 'text-green-500' : 'text-red-500'"
                  >
                    {{item.azure_mfa.is_sspr_enabled}}
                  </td>
                  <td
                    class="py-2 border-r border-l border-gray-50 border-opacity-20"
                    :class="item.azure_mfa.is_mfa_registered ? 'text-green-500' : 'text-red-500'"
                  >
                    {{item.azure_mfa.is_mfa_registered}}
                  </td>
                  <td
                    class="py-2 border-r border-l border-gray-50 border-opacity-20"
                    :class="item.azure_mfa.is_mfa_capable ? 'text-green-500' : 'text-red-500'"
                  >
                    {{item.azure_mfa.is_mfa_capable}}
                  </td>
                  <td
                    class="py-2 border-r border-l border-gray-50 border-opacity-20"
                    :class="item.azure_mfa.is_passwordless_capable ? 'text-green-500' : 'text-red-500'"
                  >
                    {{item.azure_mfa.is_passwordless_capable}}
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="7"
                    class="text-center py-2 border-r border-l border-gray-50 border-opacity-20"
                    v-if="!emailsWithAzureSearched.length"
                  >
                    No result
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="tab-content-item hidden" id="without_azure">
            <input
              class="outline-none text-black border-gray-700 rounded-lg px-4 py-2 mb-2"
              type="search"
              placeholder="Search ..."
              v-model="searchEmailsWithoutAzure"
            >
            <div class="rounded-lg overflow-hidden border-b border-gray-50 border-opacity-20">
              <table class="w-full">
                <thead>
                <tr class="bg-purple-800 border-r border-l border-purple-800">
                  <th class="px-4 py-3 border-r border-l border-gray-50 border-opacity-20">Organisation Id</th>
                  <th class="px-4 py-3 border-r border-l border-gray-50 border-opacity-20">Email</th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="(item, index) in emailsWithoutAzureSearched"
                  :key="index"
                  class="text-center bg-opacity-10"
                  :class="{'bg-gray-50': ((index % 2) == 1)}"
                >
                  <td class="py-2 border-r border-l border-gray-50 border-opacity-20">{{item.org_id}}</td>
                  <td class="py-2 border-r border-l border-gray-50 border-opacity-20">{{item.email}}</td>
                </tr>
                <tr>
                  <td
                    colspan="7"
                    class="text-center py-2 border-r border-l border-gray-50 border-opacity-20"
                    v-if="!emailsWithoutAzureSearched.length"
                  >
                    No result
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-10 h-10 mx-auto my-4" v-else>
      <SpinnerBase />
    </div>
  </div>
</template>

<script>
import { gql } from 'graphql-tag';
import SpinnerBase from '@/components/Base/SpinnerBase';
import ProofpointChart from '@/components/ProofpointChart';
import BackButton from '@/components/Base/BackButton';
// import {} from '@/utils/time';

const query = gql`
  query azureData($orgId: Int!) {
    _organisations(where: { org_id: { _eq: $orgId } }) {
      emails {
        email,
        org_id
        azure_mfa {
          id
          user_principal_name
          user_display_name
          is_sspr_registered
          is_sspr_enabled
          is_mfa_registered
          is_mfa_capable
          is_passwordless_capable
        }
      }
    }
  }
`;

export default {
  data() {
    return {
      searchEmailsWithAzure: '',
      searchEmailsWithoutAzure: '',
      emailsWithAzureSearched: '',
      emailsWithoutAzureSearched: ''
    }
  },
  components: {
    BackButton,
    SpinnerBase,
    ProofpointChart,
  },
  apollo: {
    _organisations: {
      query,
      variables() {
        return {
          orgId: this.id
        };
      }
    },
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    org() {
      return this.$store.getters['structure/GET_ORGANISATION'](this.id);
    },
    emailsWithAzure() {
      if(this._organisations) {
        return this._organisations[0].emails.filter((item) => {
          return item.azure_mfa && (!item.azure_mfa.is_mfa_registered || !item.azure_mfa.is_mfa_capable)
        })
      }
    },
    emailsWithoutAzure() {
      if(this._organisations) {
        return this._organisations[0].emails.filter((item) => !item.azure_mfa)
      }
    }
  },
  methods: {
    showTab(elementId) {
      let prevTabTitleElement = document.querySelector('.tab-title-item.bg-purple-500');
      let activeTabTitleElement = event.target;
      let prevTabContentElement = document.querySelector('.tab-content-item.visible');
      let activeTabContentElement = document.querySelector(`#${elementId}`);

      prevTabTitleElement.classList.remove('bg-purple-500');
      activeTabTitleElement.classList.add('bg-purple-500');

      prevTabContentElement.classList.remove('visible');
      prevTabContentElement.classList.add('hidden');
      activeTabContentElement.classList.remove('hidden');
      activeTabContentElement.classList.add('visible');
    }
  },
  created() {
    this.emailsWithAzureSearched = this.emailsWithAzure;
    this.emailsWithoutAzureSearched = this.emailsWithoutAzure;
  },
  watch: {
    emailsWithAzure(val) {
      this.emailsWithAzureSearched = val;
    },
    emailsWithoutAzure(val) {
      this.emailsWithoutAzureSearched = val;
    },
    searchEmailsWithAzure(keyword) {
      this.emailsWithAzureSearched = this.emailsWithAzure.filter((item) => {
        let isMatch = false;
        let isS1Match = item.azure_mfa.user_principal_name.search(keyword) > -1;
        let isS2Match = item.azure_mfa.user_display_name.search(keyword) > -1;

        if(isS1Match || isS2Match) {
          isMatch = true;
        }

        return isMatch;
      })
    },
    searchEmailsWithoutAzure(keyword) {
      this.emailsWithoutAzureSearched = this.emailsWithoutAzure.filter((item) => {
        let isMatch = false;
        let isS1Match = item.email.search(keyword) > -1;

        if(isS1Match) {
          isMatch = true;
        }

        return isMatch;
      })
    },
    // _organisations() {
    //   console.log("Azure", this._organisations)
    //   console.log("emailsWithAzure", this.emailsWithAzure)
    //   console.log("emailsWithoutAzure", this.emailsWithoutAzure)
    // },
  }
};
</script>

<style lang="scss" scoped>
.blur-drop {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
}

.tab-title-item {
  @apply px-5 py-3 rounded-tl-2xl rounded-tr-2xl border border-purple-500 border-b-0 mr-2 cursor-pointer;
}
</style>

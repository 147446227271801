<template>
  <router-link
    class="card flex flex-col"
    :class="{'cursor-auto': !numBlockedClicks}"
    :to="numBlockedClicks ? `/proofpoint/${org.org_id}` : '#'"
    :is="numBlockedClicks ? 'router-link' : 'span'"
  >
    <div
      class="flex justify-between h-12 items-center bg-bgLighter border-b border-gray-700 px-6 py-3 text-gray-300 card--title"
      :class="numBlockedClicks ? 'clickable' : 'cursor-default'"
    >
      <div>
        <img src="@/assets/proofpoint_logo.png" class="h-6 inline-block" />
        <BaseTooltip class="ml-2 inline-block" :info="'Email Security'">
          <font-awesome-icon
            class="text-gray-500"
            :icon="['fa', 'info-circle']"
          />
        </BaseTooltip>
      </div>
      <font-awesome-icon
        v-if="numBlockedClicks"
        class="text-gray-700 text-xl transform -rotate-45 icon transition-colors"
        :icon="['fa', 'arrow-right']"
      />
    </div>
    <div class="grid grid-cols-3 h-full" v-if="(proofpointData && !isAggregateMode) || isAggregateMode">
      <div class="col-span-2 flex items-center border-r border-gray-700 py-4">
        <div class="flex flex-col items-center px-6 flex-grow">
          <div class="flex items-center">
            <font-awesome-icon
              class="text-xl mr-2 text-red-500"
              :icon="['fa', 'user-shield']"
            />
            <h2 class="text-4xl" v-if="isAggregateMode && org.type != 'VBU'">{{ orgDataAggregate.vaps }}</h2>
            <h2 class="text-4xl" v-else>{{ orgData.vaps }}</h2>
          </div>
          <p class="text-sm text-gray-500 text-center">
            attacked people (last 30 days)
          </p>
        </div>
        <div class="self-stretch border-l border-gray-700 -my-4"></div>
        <div class="flex flex-col items-center px-6">
          <div class="flex items-center">
            <font-awesome-icon
              class="text-xl mr-2 text-purple-400"
              :icon="['fa', 'mouse']"
            />
            <h2 class="text-4xl" v-if="isAggregateMode && org.type != 'VBU'">{{ orgDataAggregate.top_clickers }}</h2>
            <h2 class="text-4xl" v-else>{{ orgData.top_clickers }}</h2>
          </div>
          <p class="text-sm text-gray-500 text-center">
            top clickers (last 30 days)
          </p>
        </div>
      </div>

      <div class="flex flex-col">
        <div class="p-3 border-b border-gray-700">
          <div class="flex items-center justify-center" v-if="isAggregateMode && org.type != 'VBU'">
            <font-awesome-icon
              class="mr-2"
              :class="
                orgDataAggregate.blocked_clicks_last_month >
                orgDataAggregate.blocked_clicks_prevofprev_month
                  ? 'text-red-500'
                  : 'text-green-500'
              "
              :icon="[
                'fa',
                `arrow-alt-circle-${
                  orgDataAggregate.blocked_clicks_last_month >
                  orgDataAggregate.blocked_clicks_prevofprev_month
                    ? 'up'
                    : 'down'
                }`,
              ]"
            />
            <h4 class="uppercase">
              {{ orgDataAggregate.blocked_clicks_last_month }}
            </h4>
          </div>
          <div class="flex items-center justify-center" v-else>
            <font-awesome-icon
              class="mr-2"
              :class="
                orgData.blocked_clicks_last_month >
                orgData.blocked_clicks_prevofprev_month
                  ? 'text-red-500'
                  : 'text-green-500'
              "
              :icon="[
                'fa',
                `arrow-alt-circle-${
                  orgData.blocked_clicks_last_month >
                  orgData.blocked_clicks_prevofprev_month
                    ? 'up'
                    : 'down'
                }`,
              ]"
            />
            <h4 class="uppercase">
              {{ orgData.blocked_clicks_last_month }}
            </h4>
          </div>
          <p class="text-xs text-gray-500 text-center">
            blocked clicks (last month)
          </p>
        </div>
        <div class="p-3">
          <div class="flex items-center justify-center">
            <font-awesome-icon
              class="text-yellow-400 text-sm mr-2"
              :icon="['fa', 'shield-alt']"
            />
            <h4 v-if="isAggregateMode && org.type != 'VBU'">
              {{ orgDataAggregate.blocked_clicks_this_month }}
            </h4>
            <h4 v-else>
              {{ orgData.blocked_clicks_this_month }}
            </h4>
          </div>
          <p class="text-xs text-gray-500 text-center">
            blocked clicks<br />
            (this month)
          </p>
        </div>
      </div>
      <!-- <div class="p-3 flex flex-col justify-center items-center w-2/5">

      </div> -->
    </div>
    <div class="flex items-center justify-center h-full" v-else>
      <p class="py-8 opacity-50">No users found.</p>
    </div>
  </router-link>
</template>

<script>
import BaseTooltip from '@/components/Base/BaseTooltip';

export default {
  components: {
    BaseTooltip,
  },
  props: {
    org: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isAggregateMode() {
      return this.$store.state.structure.isAggregateMode;
    },
    orgData() {
      let dataObj = {
        vaps: 0,
        top_clickers: 0,
        blocked_clicks_last_month: 0,
        blocked_clicks_this_month: 0,
      };

      if (this.proofpointData) {
        // Aggregates data in case there's more than one email_org<=>org_id connections
        for (const key in dataObj) {
          dataObj[key] = this.proofpointData.reduce((acc, curr) => {
            return (acc += curr[key]);
          }, 0);
        }
        dataObj = {
          ...dataObj,
        };
      }

      return dataObj;
    },
    orgDataAggregate() {
      let proofpointList = this.org.VBUsSystems.proofpoint;

      if(this.org.VBUsSystems && proofpointList.length) {
        let sumVBUs = proofpointList.reduce((sumObj, currentObj) => {
          return {
            vaps: (sumObj.vaps || 0) + Number(currentObj[0]?.vaps || 0),
            top_clickers: (sumObj.top_clickers || 0) + Number(currentObj[0]?.top_clickers || 0),
            blocked_clicks_last_month: (sumObj.blocked_clicks_last_month || 0) + Number(currentObj[0]?.blocked_clicks_last_month || 0),
            blocked_clicks_this_month: (sumObj.blocked_clicks_this_month || 0) + Number(currentObj[0]?.blocked_clicks_this_month || 0)
          }
        }, 0)

        return {
          vaps: Number.isInteger(sumVBUs.vaps / proofpointList.length) ?
            (sumVBUs.vaps / proofpointList.length) : (sumVBUs.vaps / proofpointList.length).toFixed(2),
          top_clickers: Number.isInteger(sumVBUs.top_clickers / proofpointList.length) ?
            (sumVBUs.top_clickers / proofpointList.length) : (sumVBUs.top_clickers / proofpointList.length).toFixed(2),
          blocked_clicks_last_month: Number.isInteger(sumVBUs.blocked_clicks_last_month / proofpointList.length) ?
            (sumVBUs.blocked_clicks_last_month / proofpointList.length) : (sumVBUs.blocked_clicks_last_month / proofpointList.length).toFixed(2),
          blocked_clicks_this_month: Number.isInteger(sumVBUs.blocked_clicks_this_month / proofpointList.length) ?
            (sumVBUs.blocked_clicks_this_month / proofpointList.length) : (sumVBUs.blocked_clicks_this_month / proofpointList.length).toFixed(2)
        };
      } else {
        return {}
      }
    },
    numBlockedClicks() {
      if (this.orgData) {
        return (
          this.orgData.blocked_clicks_last_month +
          this.orgData.blocked_clicks_this_month
        );
      }
      return null;
    },
    proofpointData() {
      return this.$store.state.proofpoint.data[this.org.org_id] || null;
    },
  },
  created() {
    // if(this.org.VBUsSystems)
    //   console.log(this.org.VBUsSystems.proofpoint)
  }
};
</script>

<style lang="scss" scoped>
.card {
  &--title {
    @apply transition-colors duration-300 relative;
  }

  &:hover {
    .card--title.clickable {
      background-color: rgba(52, 52, 62, 1);

      .icon {
        @apply text-gray-400;
      }
    }
  }
}
</style>

import { createStore } from 'vuex';
import auth from './modules/auth';
import structure from './modules/structure';
import crowdstrike from './modules/crowdstrike';
import netsparker from './modules/netsparker';
import spycloud from './modules/spycloud';
import proofpoint from './modules/proofpoint';
import knowbe4 from './modules/knowbe4';
import cisco from './modules/cisco';
import rapid7 from './modules/rapid7';
import azure from './modules/azure';
import ui from './modules/ui';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    structure,
    crowdstrike,
    netsparker,
    spycloud,
    proofpoint,
    ui,
    knowbe4,
    cisco,
    rapid7,
    azure,
  },
});

<template>
  <div class="heatmap-view">
    <div class="flex justify-between items-center">
      <h2 class="text-2xl text-gray-200 mb-4">Heatmap</h2>
      <div class="flex flex-col items-end">
        <div class="flex mb-2">
          <p class="text-sm mr-2">Show values:</p>
          <BaseToggle
            @click.self.stop
            @change="valuesHandler"
            :name="'view_aggregate'"
            :value="showValue"
          />
        </div>
        <div class="flex">
          <p class="text-sm mr-2">Colorblind mode:</p>
          <BaseToggle
            @click.self.stop
            @change="(val) => changeColorBlind(val.target.checked)"
            :name="'colorblind'"
            :value="colorBlindMode"
          />
        </div>
      </div>
    </div>
    <!-- Range -->
    <div class="my-4">
      <div class="flex justify-between mb-1">
        <p class="text-xs">Bad</p>
        <p class="text-xs">Good</p>
      </div>
      <div class="flex rounded-sm overflow-hidden">
        <div
          v-for="(colour, i) in reversedColoursScale"
          :key="i"
          class="w-full h-2"
          :style="`background-color: rgba(${colour}, 0.9)`"
        ></div>
      </div>
    </div>

    <div class="heatmap flex gap-1 max-w-full pb-4 items-start">
      <!-- COLUMN 1 -->
      <div class="grid grid-cols-1 gap-1 whitespace-nowrap flex-shrink-0">
        <div
          class="h-16 flex items-center justify-center bg-bgLight px-4 sticky top-0 z-10"
        >
          <p>Organisation / System</p>
        </div>
        <div
          v-for="(org, i) in flatStructure"
          :key="i"
          class="h-8 flex border transition-colors duration-300"
          :class="
            i == hoveredRow
              ? 'border-gray-500 hoveredBg'
              : 'border-gray-600 bg-bgLight'
          "
          @mouseover="selectHoveredRow(i)"
          @mouseleave="selectHoveredRow(null)"
        >
          <h4
            class="text-sm py-1.5 ml-6 pr-2 font-normal text-gray-300 item--name"
            :class="org.type"
          >
            {{ org.name }}
          </h4>
        </div>
      </div>
      <!-- COLUMN CROWDSRIKE -->
      <HeatmapCrowdstrike
        :flatStructure="flatStructure"
        @onRowHover="selectHoveredRow"
        @onRowClick="clickedRow = $event"
        :hoveredRow="hoveredRow"
        :clickedRow="clickedRow"
        :showValue="showValue"
      />
      <HeatmapNetsparker
        :flatStructure="flatStructure"
        @onRowHover="selectHoveredRow"
        @onRowClick="clickedRow = $event"
        :hoveredRow="hoveredRow"
        :clickedRow="clickedRow"
        :showValue="showValue"
      />
      <HeatmapProofpoint
        :flatStructure="flatStructure"
        @onRowHover="selectHoveredRow"
        @onRowClick="clickedRow = $event"
        :hoveredRow="hoveredRow"
        :clickedRow="clickedRow"
        :showValue="showValue"
      />
      <HeatmapKnowBe4
        :flatStructure="flatStructure"
        @onRowHover="selectHoveredRow"
        @onRowClick="clickedRow = $event"
        :hoveredRow="hoveredRow"
        :clickedRow="clickedRow"
        :showValue="showValue"
      />
      <HeatmapSpycloud
        :flatStructure="flatStructure"
        @onRowHover="selectHoveredRow"
        @onRowClick="clickedRow = $event"
        :hoveredRow="hoveredRow"
        :clickedRow="clickedRow"
        :showValue="showValue"
      />
      <HeatmapCisco
        :flatStructure="flatStructure"
        @onRowHover="selectHoveredRow"
        @onRowClick="clickedRow = $event"
        :hoveredRow="hoveredRow"
        :clickedRow="clickedRow"
        :showValue="showValue"
      />
      <HeatmapRapid7
        :flatStructure="flatStructure"
        @onRowHover="selectHoveredRow"
        @onRowClick="clickedRow = $event"
        :hoveredRow="hoveredRow"
        :clickedRow="clickedRow"
        :showValue="showValue"
      />
      <HeatmapAzure
        :flatStructure="flatStructure"
        @onRowHover="selectHoveredRow"
        @onRowClick="clickedRow = $event"
        :hoveredRow="hoveredRow"
        :clickedRow="clickedRow"
        :showValue="showValue"
      />
      <HeatmapWorkday
        :flatStructure="flatStructure"
        @onRowHover="selectHoveredRow"
        @onRowClick="clickedRow = $event"
        :hoveredRow="hoveredRow"
        :clickedRow="clickedRow"
        :showValue="showValue"
      />
    </div>

  </div>

  <!-- Security Summary Modal -->
  <SecuritySummaryModal ref="SecuritySummaryModal" />
</template>

<script>
import BaseToggle from '@/components/Base/BaseToggle';
import HeatmapCrowdstrike from '@/components/HeatmapCrowdstrike';
import HeatmapNetsparker from '@/components/HeatmapNetsparker';
import HeatmapSpycloud from '@/components/HeatmapSpycloud';
import HeatmapProofpoint from '@/components/HeatmapProofpoint';
import HeatmapKnowBe4 from '@/components/HeatmapKnowBe4';
import HeatmapCisco from '@/components/HeatmapCisco';
import HeatmapRapid7 from '@/components/HeatmapRapid7';
import HeatmapAzure from '@/components/HeatmapAzure';
import HeatmapWorkday from '@/components/HeatmapWorkday';

import { debounce } from 'debounce';

import { coloursScale } from '@/utils/utils';
import SecuritySummaryModal from '@/components/SecuritySummaryModal';

export default {
  components: {
    SecuritySummaryModal,
    BaseToggle,
    HeatmapCrowdstrike,
    HeatmapNetsparker,
    HeatmapSpycloud,
    HeatmapProofpoint,
    HeatmapKnowBe4,
    HeatmapCisco,
    HeatmapRapid7,
    HeatmapAzure,
    HeatmapWorkday,
  },
  data() {
    return {
      hoveredRow: null,
      clickedRow: null,
      showValue: false,
    };
  },
  computed: {
    colorBlindMode() {
      return this.$store.state.ui.colorblindMode;
    },
    reversedColoursScale() {
      return [...coloursScale(this.colorBlindMode)].reverse();
    },
    selectedHierarchy() {
      return this.$store.state.structure.selectedHierarchy;
    },
    hierarchy() {
      if (this.selectedHierarchy.length) {
        return this.selectedHierarchy;
      } else {
        return this.$store.state.structure.hierarchy;
      }
    },
    flatStructure() {
      let flatStructureArr = [];

      this.hierarchy.forEach((level) => {
        flatStructureArr.push(this.restructurize(level));
      });

      return flatStructureArr.flat();
    },
  },
  methods: {
    selectHoveredRow: debounce(function (row) {
      this.hoveredRow = row;
    }, 20),
    restructurize(level, acc = []) {
      if (!level?.children) {
        return level;
      }

      let childrenObjects = level.children.flatMap((el) =>
        this.restructurize(el, acc)
      );

      const newLevel = { ...level };
      delete newLevel.children;

      return [...acc, newLevel, ...childrenObjects];
    },
    valuesHandler() {
      this.showValue = !this.showValue;
    },
    changeColorBlind(val) {
      this.$store.commit('ui/SET_COLORBLIND', val);
    },
  },
};
</script>

<style lang="scss">
body.blur {
  .heatmap-view,
  .main-header,
  .sidebar-toggle-button,
  .sidebar {
    filter: blur(6px);
  }
}
// .heatmap {
//   height: 70vh;
// }
.heatmap-view {
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  /* button */
  ::-webkit-scrollbar-button {
    height: 0px;
    width: 5px;
    background: #222;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #393950;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply cursor-pointer;

    background: #5a5a7e;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #1d1d27;
  }
  /* The track NOT covered by the handle. */
  // ::-webkit-scrollbar-track-piece {
  //     background: rgb(25, 25, 26);
  // }
  /* Corner */
  ::-webkit-scrollbar-corner {
    background: #999;
  }
  /* Resizer */
  ::-webkit-resizer {
    background: #111;
  }
}
.heatmap-item {
  @apply h-8 flex mt-1 transition-colors duration-300 rounded-sm bg-gray-600 cursor-pointer;

  p {
    @apply text-white text-center w-full py-2;

    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
    font-size: 0.65rem;
  }
}
.item--name {
  @apply relative;

  &::after {
    @apply absolute top-1/2 transform -translate-y-1/2 w-1.5 h-1.5 rounded-full;

    content: '';
    left: -1rem;
  }

  &.vbu::after {
    @apply bg-gray-400;
  }

  &.group::after {
    @apply bg-green-500;
  }

  &.portfolio::after {
    @apply bg-blue-500;
  }

  &.sub-portfolio::after {
    @apply opacity-100 bg-yellow-600;
  }
}

.hoveredBg {
  background: #353541;
}
</style>

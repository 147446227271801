<template>
  <div class="hello">
    <div class="flex justify-between items-center">
      <h2 class="text-2xl text-gray-200 mb-4">Dashboard</h2>
      <div class="flex">
        <p class="text-sm mr-2">Show aggregate:</p>
        <BaseToggle
          @click.self.stop
          :name="'view_aggregate'"
          @change="toggleAggregateMode()"
          :value="isAggregateMode"
        />
      </div>
    </div>
    <div v-if="selectedHierarchy.length">
      <DashboardObject
        v-for="organisation in selectedHierarchy"
        :organisation="organisation"
        :key="organisation.org_id"
      />
    </div>
    <div v-else class="bg-bgLight py-8 text-center rounded-md">
      <p>Please use filter for see organisations data.</p>
      <button
        class="bg-gray-700 text-gray-200 px-4 py-2 rounded-md mt-4 text-sm"
        @click="showAll"
        v-if="!isLoading"
      >
        Show All
      </button>
      <div class="w-10 h-10 mx-auto mt-4" v-else>
        <SpinnerBase />
      </div>
    </div>
  </div>
</template>

<script>
import BaseToggle from '@/components/Base/BaseToggle';
import SpinnerBase from '@/components/Base/SpinnerBase';
import DashboardObject from '@/components/DashboardObject';

export default {
  name: 'Dashboard',
  components: {
    BaseToggle,
    DashboardObject,
    SpinnerBase,
  },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    toggleAggregateMode() {
      this.$store.commit('structure/SET_TOGGLE_AGGREGATE_MODE');
    },
    showAll() {
      this.isLoading = true;

      setTimeout(() => {
        this.isLoading = false;
      }, 2000);

      setTimeout(() => {
        this.$store.commit('structure/SHOW_ALL');
      }, 50);
    },
  },
  computed: {
    isAggregateMode() {
      return this.$store.state.structure.isAggregateMode;
    },
    selectedHierarchy() {
      return this.$store.state.structure.selectedHierarchy;
    },
  },
  mounted() {
    let _this = this;
    let isPagePreparedInterval = setInterval(function(){
      let cardElement = document.querySelector('.grid .card');
      if(cardElement && cardElement.offsetHeight > 100) {
        // console.log("cardElement.offsetHeight", cardElement.offsetHeight)
        let scrollInfo = _this.$router.options.history.state.scroll;
        if(scrollInfo) {
          window.scrollTo(0, scrollInfo.top);
        }

        clearInterval(isPagePreparedInterval);
      }
    }, 100)
  }
};
</script>

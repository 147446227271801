<template>
  <div>
    <BackButton />

    <div v-if="!$apollo.queries._netsparker_details.loading">
      <div class="flex justify-between">
        <div>
          <h4 class="text-lg mr-auto">{{ org.name }}</h4>
          <p class="mb-6">
            Number of issues detected in the 6 most recent website scans
          </p>
        </div>
        <div class="text-center my-2">
          <img src="@/assets/invicti_logo.png" class="h-8 inline-block" />
        </div>
      </div>

      <div
        class="border-t border-gray-800 p-8"
        v-for="website in orgWebsites"
        :key="website.website_id"
      >
        <p class="text-gray-500 text-lg mb-1.5">
          Website:
          <a
            href="{{ website.root_url }}"
            target="_blank"
            rel="noopener noreferer"
            class="underline text-gray-400"
            >{{ website.root_url }}</a
          >
        </p>
        <p class="mb-4">
          Outstanding issues:
          <span class="text-gray-300 mr-1">
            {{ website.scan_details[0].issues_aggregate.aggregate.count }}
          </span>
          <font-awesome-icon
            class="text-yellow-400 text-xs inline-block"
            v-if="website.scan_details[0].issues_aggregate.aggregate.count > 0"
            :icon="['fa', 'exclamation-triangle']"
          />
        </p>
        <NetsparkChart :scans="websiteScans(website.website_id)" />
      </div>
    </div>
    <div class="w-10 h-10 mx-auto my-4" v-else>
      <SpinnerBase />
    </div>
  </div>
</template>

<script>
import { gql } from 'graphql-tag';
import NetsparkChart from '@/components/NetsparkChart';
import SpinnerBase from '@/components/Base/SpinnerBase';
import BackButton from '@/components/Base/BackButton';

const query = gql`
  query getWebsitesData($websitesIds: [String!]) {
    _netsparker_details(
      where: { website_id: { _in: $websitesIds } }
      limit: 6
      order_by: { scan_date: desc }
    ) {
      best_practice_count
      critical_count
      high_count
      info_count
      low_count
      medium_count
      scan_date
      scan_id
      state
      website_id
      issues_aggregate(where: { is_todo: { _eq: true } }) {
        aggregate {
          count
        }
      }
    }
  }
`;

export default {
  components: {
    BackButton,
    NetsparkChart,
    SpinnerBase,
  },
  apollo: {
    _netsparker_details: {
      query,
      variables() {
        return {
          websitesIds: this.orgWebsites.map((el) => el.website_id).reverse(),
        };
      },
    },
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    org() {
      return this.$store.getters['structure/GET_ORGANISATION'](this.id);
    },
    orgWebsites() {
      return this.$store.state.netsparker.websitesIds[this.id] || [];
    },
  },
  methods: {
    websiteScans(websiteId) {
      console.log("_netsparker_details", this._netsparker_details)
      return this._netsparker_details.filter(
        (scan) => scan.website_id == websiteId
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>

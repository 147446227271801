<template>
  <div>
    <BackButton />

    <div v-if="!$apollo.queries._organisations.loading">
      <div class="flex justify-between">
        <div>
          <h4 class="text-lg mr-auto">{{ org.name }}</h4>
          <p class="mb-6">
            Number of requests blocked over the preceding 6 months
          </p>
        </div>
        <div class="text-center my-2">
          <img src="@/assets/cisco-logo.png" class="h-20 inline-block" />
        </div>
      </div>

      <div class="relative py-4">
        <div
          v-if="!chartData.labels.length"
          class="bg-green-900 flex items-center justify-center inset-0 blur-drop text-center rounded-md absolute z-10"
        >
          <p>No detections found.</p>
        </div>
        <CiscoChart :chartData="chartData" />
      </div>
    </div>
    <div class="w-10 h-10 mx-auto my-4" v-else>
      <SpinnerBase />
    </div>
  </div>
</template>

<script>
import { gql } from 'graphql-tag';
import SpinnerBase from '@/components/Base/SpinnerBase';
import CiscoChart from '@/components/CiscoChart';
import BackButton from '@/components/Base/BackButton';
// import {} from '@/utils/time';

const query = gql`
  query ciscoData($orgId: Int!, $sixMonthsAgo: date!) {
    _organisations(where: { org_id: { _eq: $orgId } }) {
      org_id
      blocked_clicks: cisco {
        summary(where: { start_date: { _gte: $sixMonthsAgo } }) {
          requestsblocked
          start_date
        }
      }
    }
  }
`;

export default {
  components: {
    BackButton,
    SpinnerBase,
    CiscoChart,
  },
  apollo: {
    _organisations: {
      query,
      variables() {
        return {
          orgId: this.id,
          sixMonthsAgo: this.sixMonthsAgo,
        };
      },
    },
  },
  computed: {
    months() {
      var today = new Date();
      var d;
      var month;
      var newArr = [];
      for (var i = 5; i > 0; i -= 1) {
        d = new Date(today.getFullYear(), today.getMonth() - i, 1);
        month = d.toLocaleString('en-GB', { month: 'long' }).toUpperCase();
        newArr.push(month);
      }
      newArr.push(
        new Date().toLocaleString('en-GB', { month: 'long' }).toUpperCase()
      );
      return newArr;
    },
    id() {
      return this.$route.params.id;
    },
    org() {
      return this.$store.getters['structure/GET_ORGANISATION'](this.id);
    },
    sixMonthsAgo() {
      const date = new Date();
      date.setMonth(date.getMonth() - 6);

      return new Date(date.getFullYear(), date.getMonth(), 1);
    },
    blockedClicks() {
      if (!this._organisations[0]) return;
      let blockedClicksArr = [];
      this._organisations.forEach((org) => {
        org.blocked_clicks.summary.forEach((block) => {
          blockedClicksArr.push(block);
        });
      });
      return blockedClicksArr;
    },
    emailsNum() {
      return this._organisations[0].emails_num;
    },
    chartData() {
      const clicks = this.blockedClicks;
      const months = this.months;
      if (!clicks || !this.months) return;
      const data = {
        labels: months,
        datasets: [
          {
            data: months.map((d) => 0),
            backgroundColor: 'rgba(139, 92, 246, 0.7)',
          },
        ],
      };

      if (clicks && clicks.length) {
        clicks.forEach((c) => {
          const dateMonth = new Date(c.start_date)
            .toLocaleString('en-GB', { month: 'long' })
            .toUpperCase();

          const monthIn = c.start_date
            .toLocaleString('en-GB', { month: 'long' })
            .toUpperCase();
          console.log(monthIn);
          console.log(months.indexOf(dateMonth));
          data.datasets[0].data[months.indexOf(dateMonth)] = c.requestsblocked;
        });
      }

      return data;
    },
  },
  methods: {
    websiteScans(websiteId) {
      return this._netsparker_details.filter(
        (scan) => scan.website_id == websiteId
      );
    },
    // back() {
    //   console.log()
    //   let backPath = this.$router.options.history.state.back;
    //   if(backPath == '/') {
    //     this.$emit('back-to-dashboard', {msg: 'hello'})
    //   }
    //   this.$router.back();
    // }
  },
};
</script>

<style lang="scss" scoped>
.blur-drop {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
}
</style>

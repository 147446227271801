<template>
  <!-- COLUMN Netsparker -->
  <div class="w-full">
    <HeatmapHeader
      :infoLeft="'Operational: Number of critical, high and medium vulnerabilities. Red if any critical vulnerabilities are present'"
      :infoRight="'Deployment: Grey if not registered/never been scanned. Green for recently scanned going red the older the last scan'"
    >
      <img src="@/assets/invicti_logo.png" class="h-4 mt-2.5" />
    </HeatmapHeader>
    <div class="grid grid-cols-2 gap-1">
      <!-- left column -->
      <div class="w-full">
        <div
          v-for="(org, i) in flatStructure"
          :key="i"
          class="heatmap-item"
          :class="i == hoveredRow && 'border border-gray-200'"
          :style="`background-color: rgba(${colorsNumb[i].operational.color}, ${
            i == hoveredRow ? '1' : '0.8'
          })`"
          @mouseover="$emit('onRowHover', i)"
          @mouseleave="$emit('onRowHover', null)"
          @click="heatmapItemClicked(i, colorsNumb[i], org)"
        >
          <p v-show="showValue || i == hoveredRow">
            {{
              colorsNumb[i].operational.value >= 0
                ? colorsNumb[i].operational.value
                : '--'
            }}
          </p>
        </div>
      </div>
      <!-- right column -->
      <div class="w-full">
        <div
          v-for="(org, i) in flatStructure"
          :key="i"
          class="heatmap-item"
          :class="i == hoveredRow && 'border border-gray-200'"
          :style="`background-color: rgba(${colorsNumb[i].deployment.color}, ${
            i == hoveredRow ? '1' : '0.8'
          })`"
          @mouseover="$emit('onRowHover', i)"
          @mouseleave="$emit('onRowHover', null)"
          @click="heatmapItemClicked(i, colorsNumb[i], org)"
        >
          <p v-show="showValue || i == hoveredRow">
            {{
              colorsNumb[i].deployment.value >= 0
                ? colorsNumb[i].deployment.value + ' days'
                : '--'
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeatmapHeader from '@/components/HeatmapHeader';

import { normaliseValue, normaliseColour } from '@/utils/utils';

export default {
  components: {
    HeatmapHeader,
  },
  props: {
    flatStructure: {
      type: Array,
      required: true,
    },
    hoveredRow: {
      type: [Number, null],
      default: null,
    },
    clickedRow: {
      type: [Number, null],
      default: null,
    },
    showValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colorsNumb() {
      return this.flatStructure.map((el) => {
        const initValues = {
          operational: { color: NaN, value: '-' },
          deployment: { color: NaN, value: '-' },
        };

        const orgWebsites =
          this.$store.state.netsparker.websitesIds[el.org_id] || [];

        const scans = orgWebsites.flatMap((el) => el.scan_details);

        if (scans.length) {
          // SHOWS LAST SCAN
          scans.sort(
            (scanA, scanB) =>
              new Date(scanA.scan_date).getTime() -
              new Date(scanB.scan_date).getTime()
          );

          const lastScanDays = Math.floor(
            (new Date().getTime() - new Date(scans[0].scan_date).getTime()) /
              (1000 * 3600 * 24)
          );

          initValues.deployment = {
            value: lastScanDays,
            color: this.normaliseColour(
              this.normaliseValue(lastScanDays, 0, 100),
              false,
              this.colorBlindMode
            ),
          };

          const outsanding_issues = orgWebsites.reduce((acc, curr) => {
            return (
              acc + curr.scan_details[0]?.issues_aggregate.aggregate.count || 0
            );
          }, 0);

          initValues.operational = {
            value: outsanding_issues,
            color: this.normaliseColour(
              this.normaliseValue(outsanding_issues, 0, 20),
              false,
              this.colorBlindMode
            ),
          };
        }

        // if (orgWebsites.length) {
        //   // const [currMonthData, prevMonthData] =
        //   //   this.$store.getters['crowdstrike/GET_LAST_THREE_MONTHS_DATA'](cid);

        //   const operationalVal = Math.random();
        //   // prevMonthData?.devices > 0
        //   //   ? prevMonthData?.detections / prevMonthData?.devices
        //   //   : NaN;
        //   const deployVal = 1;

        //   return {
        //     operational: {
        //       value: operationalVal,
        //       color: this.normaliseColour(
        //         this.normaliseValue(operationalVal, 0, 0.3),
        //         false,
        //         this.colorBlindMode
        //       ),
        //     },
        //     deployment: {
        //       value: Math.round(deployVal * 100),
        //       color: this.normaliseColour(
        //         this.normaliseValue(deployVal, 0, 1),
        //         true,
        //         this.colorBlindMode
        //       ),
        //     },
        //   };
        // }

        return initValues;
      });
    },
    colorBlindMode() {
      return this.$store.state.ui.colorblindMode;
    },
  },
  created() {
    // console.log("colorsNumb", this.colorsNumb)
    // console.log("this.$store.state", this.$store.state)

  },
  methods: {
    normaliseValue,
    normaliseColour,
    heatmapItemClicked(i, color, org) {
      this.$emit('onRowClick', i)

      this.$store.commit('structure/SET_SECURITY_SUMMARY_MODAL_INFO', {
        system: 'netsparker',
        index: i,
        color: color,
        org: org
      })

      this.$vfm.show('security-summary-modal');
    }
  },
  watch: {
    clickedRow(clickedRowIndex) {
      this.$store.commit('structure/SET_SECURITY_SUMMARY_MODAL_INFO', {
        system: 'netsparker',
        index: clickedRowIndex,
        color: this.colorsNumb[clickedRowIndex],
        org: this.flatStructure[clickedRowIndex]
      })
    }
  }
};
</script>

<style lang="scss" scoped></style>

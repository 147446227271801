<template>
  <div class="search-box relative">
    <form @submit.prevent="search()" class="flex">
      <div class="form-item relative">
        <input
          type="search"
          class="outline-none text-black border-gray-700 rounded-lg px-4 py-2"
          v-model="keyword"
          @focusin="suggestionIsVisible = true"
          @focusout="focusOutSearchInput()"
          @input="fuzzySearch()"
        >
        <div
          class="suggestion-list bg-white py-1 rounded-lg absolute top-11 z-20 w-full overflow-hidden"
          v-show="fuseResult.length > 0 && suggestionIsVisible"
        >
          <ul>
            <li v-for="(item, index) in fuseResult" :key="index">
              <a
                href=""
                @click.prevent="selectSuggestionItem(item.name)"
                class="block px-4 py-1 text-black transition hover:bg-purple-200"
               >{{ item.name }}</a>
            </li>
          </ul>
        </div>
      </div>
      <button
        class="flex justify-center items-center px-3 py-2 ml-2 bg-purple-400 hover:bg-purple-600 rounded-3xl transition"
        type="submit"
      >
        <font-awesome-icon
          class="text-white"
          :icon="['fa', 'search']"
        />
      </button>
    </form>
<!--    <div class="text-primary text-sm pl-2">Please enter at least 3 characters</div>-->
  </div>
</template>

<script>
import Fuse from 'fuse.js';

export default {
  name: 'SearchBox',

  data() {
    return {
      keyword: "",
      searchResult: [],
      hierarchyOneLevel: [],
      fuseOptions: {
        keys: ['name'],
        threshold: 0.1
      },
      fuseResult: [],
      suggestionIsVisible: false
    }
  },

  computed: {
    hierarchy() {
      return this.$store.state.structure.hierarchy;
    },
  },

  methods: {
    search() {
      // let hierarchyData = [];
      // if ((typeof this.keyword == 'string') && this.keyword.length >= 3) {
      //   hierarchyData = this.fuseResult;
      // } else {
      //   hierarchyData = this.hierarchy[0];
      // }

      this.$store.commit('structure/SELECT_HIERARCHY', {
        hierarchy: this.fuseResult
      });
    },

    hierarchyObjectToOneLevelArray(obj) {
      this.hierarchyOneLevel.push({
        actual_devices: obj.actual_devices,
        name: obj.name,
        org_id: obj.org_id,
        reports_to: obj.reports_to,
        type: obj.type,
        __typename: obj.__typename
      })

      if (Array.isArray(obj.children) && obj.children.length > 0) {
        obj.children.forEach((item) => {
          this.hierarchyObjectToOneLevelArray(item)
        })
      }
    },

    fuzzySearch() {
      // if ((typeof this.keyword == 'string') && this.keyword.length < 3) {
      //   this.fuseResult = [];
      //   return;
      // }

      this.hierarchyOneLevel = [];
      this.hierarchyObjectToOneLevelArray(this.hierarchy[0])

      const fuse = new Fuse(this.hierarchyOneLevel, this.fuseOptions)
      this.fuseResult = fuse.search(this.keyword)

      this.fuseResult.forEach((item) => {
        item.actual_devices = item.item.actual_devices,
          item.name = item.item.name,
          item.org_id = item.item.org_id,
          item.reports_to = item.item.reports_to,
          item.type = item.item.type,
          item.__typename = item.item.__typename
      })
    },

    selectSuggestionItem(itemName) {
      this.keyword = itemName;
      this.suggestionIsVisible = false;
      this.fuzzySearch();
      this.search();
    },

    focusOutSearchInput() {
      setTimeout(function(){
        this.suggestionIsVisible = false
      }, 100)
    }
  }
}
</script>

<style lang="scss">
.search-box {
  .suggestion-list {
    max-height: 200px;
  }
}
</style>


<template>
  <!-- COLUMN Netsparker -->
  <div class="w-full">
    <HeatmapHeader
      :infoLeft="'Operational: Percentage of requests blocked compared to requests allowed'"
      :infoRight="'Deployment: Red = not deployed, green = deployed'"
    >
      <img src="@/assets/cisco-logo.png" class="h-4 mt-2.5" />
    </HeatmapHeader>
    <div class="grid grid-cols-2 gap-1">
      <!-- left column -->
      <div class="w-full">
        <div
          v-for="(org, i) in flatStructure"
          :key="i"
          class="heatmap-item"
          :class="i == hoveredRow && 'border border-gray-200'"
          :style="`background-color: rgba(${colorsNumb[i].operational.color}, ${
            i == hoveredRow ? '1' : '0.8'
          })`"
          @mouseover="$emit('onRowHover', i)"
          @mouseleave="$emit('onRowHover', null)"
          @click="heatmapItemClicked(i, colorsNumb[i], org)"
        >
          <p v-show="showValue || i == hoveredRow">
            {{
              colorsNumb[i].operational.value >= 0
                ? `${colorsNumb[i].operational.value}%`
                : '--'
            }}
          </p>
        </div>
      </div>
      <div class="w-full">
        <div
          v-for="(org, i) in flatStructure"
          :key="i"
          class="heatmap-item"
          :class="i == hoveredRow && 'border border-gray-200'"
          :style="`background-color: rgba(${colorsNumb[i].deployment.color}, ${
            i == hoveredRow ? '1' : '0.8'
          })`"
          @mouseover="$emit('onRowHover', i)"
          @mouseleave="$emit('onRowHover', null)"
          @click="heatmapItemClicked(i, colorsNumb[i], org)"
        >
          <p v-show="showValue || i == hoveredRow">
            {{ colorsNumb[i].deployment.value }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeatmapHeader from '@/components/HeatmapHeader';

import { normaliseValue, normaliseColour } from '@/utils/utils';

export default {
  components: {
    HeatmapHeader,
  },
  props: {
    flatStructure: {
      type: Array,
      required: true,
    },
    hoveredRow: {
      type: [Number, null],
      default: null,
    },
    clickedRow: {
      type: [Number, null],
      default: null,
    },
    showValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colorsNumb() {
      return this.flatStructure.map((el) => {
        const ciscoList = this.$store.state.cisco.data[el.org_id];

        const heatmapValues = {
          operational: { color: NaN, value: '-' },
          deployment: { color: NaN, value: '-' },
        };

        const isDeployed = ciscoList?.length ? ciscoList[0].domains : false;
        // DEPLOYMENT VALUE
        heatmapValues.deployment = {
          value: Boolean(isDeployed),
          color: this.normaliseColour(
            this.normaliseValue(isDeployed ? 1 : 0, 0, 1),
            true,
            this.colorBlindMode
          ),
        };

        // OPERATIONAL VALUE
        let blockedPercent = this.percentageBlocked(ciscoList && ciscoList[0])

        let oprValNormalise;
        switch (true) {
          case blockedPercent <= 0.2:
            oprValNormalise = 1;
            break;
          case blockedPercent > 0.2 && blockedPercent <= 0.4:
            oprValNormalise = 0.8;
            break;
          case blockedPercent > 0.4 && blockedPercent <= 0.6:
            oprValNormalise = 0.4;
            break;
          case blockedPercent > 0.6 && blockedPercent <= 0.8:
            oprValNormalise = 0.1;
            break;
          case blockedPercent > 0.8:
            oprValNormalise = 0;
            break;
        }

        heatmapValues.operational = {
          value: blockedPercent,
          color: isNaN(blockedPercent) ? NaN : this.normaliseColour(
            oprValNormalise,
            true,
            this.colorBlindMode
          ),
        };

        // if (isDeployed) {
        //   const lastMonthBreaches =
        //     this.$store.state.spycloud.breaches?.[el.org_id]?.[0]
        //       ?.prev_breaches_num || 0;
        //   // OPERATIONAL VALUE
        //   heatmapValues.operational = {
        //     value: lastMonthBreaches,
        //     color: this.normaliseColour(
        //       this.normaliseValue(lastMonthBreaches, 0, 5),
        //       false,
        //       this.colorBlindMode
        //     ),
        //   };
        // }

        // console.log("heatmapValues", heatmapValues)

        return heatmapValues;
      });
    },
    colorBlindMode() {
      return this.$store.state.ui.colorblindMode;
    },
  },
  methods: {
    normaliseValue,
    normaliseColour,
    heatmapItemClicked(i, color, org) {
      this.$emit('onRowClick', i)

      this.$store.commit('structure/SET_SECURITY_SUMMARY_MODAL_INFO', {
        system: 'cisco',
        index: i,
        color: color,
        org: org
      })

      this.$vfm.show('security-summary-modal');
    },
    percentageBlocked(data) {
      if (!data || !data.curr_month_requests)
        return '-';

      const blocked = ((data.curr_month_blocks / data.curr_month_requests) * 100);

      return Number.isInteger(blocked) ? blocked : blocked.toFixed(2);
    }
  },
  watch: {
    clickedRow(clickedRowIndex) {
      this.$store.commit('structure/SET_SECURITY_SUMMARY_MODAL_INFO', {
        system: 'cisco',
        index: clickedRowIndex,
        color: this.colorsNumb[clickedRowIndex],
        org: this.flatStructure[clickedRowIndex]
      })
    },
    colorsNumb(val) {
      console.log("colorsNumb", val)
    }
  },
};
</script>

<style lang="scss" scoped></style>

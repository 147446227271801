<template v-observe-visibility="{ callback: visibilityChanged, once: true }">
  <router-link
    class="card flex flex-col"
    :class="{'cursor-auto': !(riskScore || lastCourse)}"
    :to="riskScore || lastCourse ? `/knowbe4/${org.org_id}` : '#'"
    v-if="!$apollo.queries._organisations.loading"
  >
    <div
      class="flex justify-between h-12 items-center bg-bgLighter border-b border-gray-700 px-6 py-3 text-gray-300 card--title"
      :class="riskScore && lastCourse && 'clickable'"
    >
      <div class="flex items-center">
        <img src="@/assets/knowbe4-logo.png" class="h-5" />
        <BaseTooltip
          class="ml-2 inline-block"
          :info="'Cyber Security Training'"
        >
          <font-awesome-icon
            class="text-gray-500"
            :icon="['fa', 'info-circle']"
          />
        </BaseTooltip>
      </div>
      <!-- this div below just holds the header size beacuse logo is smaller -->
      <div class="h-6"></div>
      <font-awesome-icon
        v-if="riskScore && lastCourse"
        class="text-gray-700 text-xl transform -rotate-45 icon transition-colors"
        :icon="['fa', 'arrow-right']"
      />
    </div>
    <div class="w-full h-full flex items-center" v-if="riskScore || lastCourse">
      <div class="flex flex-col items-center px-6 py-4 w-full">
        <div class="flex items-center">
          <font-awesome-icon
            v-if="lastCourse"
            class="text-xl mr-2"
            :class="
              lastCourse.completion_percentage < 50
                ? 'text-red-500'
                : 'text-green-500'
            "
            :icon="['fa', 'certificate']"
          />
          <h2 class="text-4xl">
            {{
              lastCourse.completion_percentage
                ? lastCourse.completion_percentage + '%'
                : '-'
            }}
          </h2>
        </div>
        <p class="text-sm text-gray-500 text-center">
          Completion of most recent course:
          <span class="text-xs text-gray-400 italic">{{
            lastCourse.name || '-'
          }}</span>
        </p>
      </div>
      <div class="self-stretch border-l border-gray-700"></div>
      <div class="flex flex-col items-center justify-center h-full px-6 w-full">
        <div class="flex items-center" :style="textColorStyle">
          <font-awesome-icon
            v-if="riskScore"
            class="text-xl mr-2"
            :icon="['fa', 'exclamation-circle']"
          />
          <h2 class="text-4xl">{{ riskScore || '-' }}</h2>
        </div>
        <p class="text-sm text-gray-500 text-center">
          highest user <br />risk score
        </p>
      </div>
    </div>
    <div class="flex items-center justify-center h-full" v-else>
      <p class="py-8 opacity-50">No users found.</p>
    </div>
  </router-link>
  <div class="card flex flex-col" v-else>
    <div
      class="h-12 items-center bg-bgLighter border-b border-gray-700 px-6 py-3 text-gray-300 card--title"
    >
      <div class="flex items-center">
        <img src="@/assets/knowbe4-logo.png" class="h-5" />
        <BaseTooltip
          class="ml-2 inline-block"
          :info="'Cyber Security Training'"
        >
          <font-awesome-icon
            class="text-gray-500"
            :icon="['fa', 'info-circle']"
          />
        </BaseTooltip>
      </div>
      <!-- this div below just holds the header size beacuse logo is smaller -->
      <div class="h-6"></div>
    </div>
    <div class="flex items-center h-full">
      <div class="mx-auto w-8 h-8">
        <SpinnerBase />
      </div>
    </div>
  </div>
</template>

<script>
import { gql } from 'graphql-tag';
import SpinnerBase from '@/components/Base/SpinnerBase';
import BaseTooltip from '@/components/Base/BaseTooltip';

const query = gql`
  query getSpyCloudEmails($orgId: Int!) {
    _organisations(where: { org_id: { _eq: $orgId } }) {
      latest_course: knowbe4_user(
        limit: 1
        order_by: { enrollments_aggregate: { max: { start_date: desc } } }
      ) {
        enrollments(order_by: { start_date: desc }, limit: 1) {
          campaign {
            name
            start_date
            completion_percentage
          }
        }
      }
      risk_score: knowbe4_user_aggregate {
        aggregate {
          max {
            current_risk_score
          }
        }
      }
    }
  }
`;

export default {
  props: {
    org: {
      type: Object,
      required: true,
    },
  },
  components: { SpinnerBase, BaseTooltip },
  apollo: {
    _organisations: {
      query,
      variables() {
        return {
          orgId: this.org.org_id,
        };
      },
      skip() {
        return this.isVisible;
      },
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    visibilityChanged(isVisible, entry) {
      this.isVisible = isVisible;
    },
  },
  computed: {
    orgData() {
      return this.org.systems.spycloud;
    },
    lastCourse() {
      // if(this.org.name == 'Rick Bacchus Portfolio') {
      //   console.log("_orga", this._organisations)
      // }

      if (
        !this._organisations ||
        !this._organisations.length ||
        !this._organisations[0].latest_course.length ||
        !this._organisations[0].latest_course[0].enrollments.length ||
        !this._organisations[0].latest_course[0].enrollments[0].campaign.length
      ) {
        return false;
      }

      return this._organisations[0].latest_course[0].enrollments[0].campaign[0];
    },
    riskScore() {
      if (!this._organisations || !this._organisations.length) {
        return false;
      }
      return this._organisations[0].risk_score.aggregate.max.current_risk_score;
    },
    textColorStyle() {
      let rgb = '255, 255, 255';

      if (this.riskScore <= 20) {
        rgb = '117, 226, 70';
      } else if (this.riskScore <= 40) {
        rgb = '228, 248, 73';
      } else if (this.riskScore <= 60) {
        rgb = '248, 158, 73';
      } else if (this.riskScore <= 80) {
        rgb = '248, 108, 73';
      } else if (this.riskScore <= 100) {
        rgb = '248, 73, 73';
      }

      return { color: `rgb(${rgb})` };
    },
  },
  created() {
    // console.log("this._organisations", this._organisations)
    // if(this.org.VBUsSystems)
    //   console.log(this.org.VBUsSystems.knowbe4)
  }
};
</script>

<style lang="scss" scoped>
.card {
  &--title {
    @apply transition-colors duration-300 relative;
  }

  &:hover {
    .card--title.clickable {
      background-color: rgba(52, 52, 62, 1);

      .icon {
        @apply text-gray-400;
      }
    }
  }
}
</style>

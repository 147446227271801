<template>
  <div
    class="hierarchy-tree"
    :style="indent"
    :class="node?.children?.length && 'mt-1.5'"
  >
    <div class="relative">
      <button
        v-if="node?.children?.length"
        @click="toggleChildren"
        class="text-xs px-1 bg-gray-700 rounded-sm mr-2.5 hover:bg-indigo-500 absolute -left-6 top-1/2 transform -translate-y-1/2 z-10"
      >
        <font-awesome-icon
          :icon="['fa', 'chevron-down']"
          class="transform transition-transform mt-0.5"
          :class="!showChildren && 'rotate-180'"
        />
      </button>
      <button
        class="text-sm text-gray-300 hover:text-gray-100 hover:underline title"
        :class="node.type.toLowerCase()"
        @click="selectHierarchy"
      >
        {{ node.name }}
      </button>
    </div>
    <transition name="fadeQuick">
      <div v-show="showChildren">
        <HierarchyTree
          v-for="child in node.children"
          :key="child.org_id"
          :node="child"
          :depth="depth + 1"
          @close="$emit('close')"
        />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'HierarchyTree',
  props: {
    node: {
      type: Object,
      required: true,
    },
    depth: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      showChildren: true,
    };
  },
  computed: {
    indent() {
      return { paddingLeft: `${this.depth * 0.2}rem` };
    },
    sidebarMenuMode() {
      return this.$store.state.structure.sidebarMenuMode;
    },
  },
  methods: {
    toggleChildren() {
      this.showChildren = !this.showChildren;
    },
    selectHierarchy() {
      if (this.node.name == 'Volaris Consolidated') {
        this.$emit('showAll');
        return;
      }
      this.$store.commit('structure/SELECT_HIERARCHY', {
        hierarchy: [this.node],
      });

      this.$emit('close');
    },
  },
  mounted() {
    if(this.depth > 2) {
      this.showChildren = false;
    }
  },
  watch: {
    sidebarMenuMode(val) {
      if(val == 'expand') {
        this.showChildren = true;
      } else {
        if(this.depth > 2) {
          this.showChildren = false;
        } else {
          this.showChildren = true;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.hierarchy-tree {
  @apply relative;

  .title {
    @apply relative;

    &::after {
      @apply absolute top-1/2 transform -translate-y-1/2 w-1 h-1 rounded-full;

      content: '';
      right: -0.75rem;
    }

    &.vbu::after {
      @apply bg-gray-400;
    }

    &.group::after {
      @apply bg-green-500;
    }

    &.portfolio::after {
      @apply bg-blue-500;
    }

    &.sub-portfolio::after {
      @apply bg-yellow-600;
    }

    &.vbu::before {
      @apply bg-gray-400 opacity-20;
    }

    &.group::before {
      @apply bg-green-500 opacity-60;
    }

    &.portfolio::before {
      @apply bg-blue-500 opacity-60;
    }

    &.sub-portfolio::before {
      @apply bg-yellow-600 opacity-60;
    }

    &::before {
      @apply absolute top-1/2 transform -translate-y-1/2 w-72 rounded-full bg-gray-400 opacity-40;

      content: '';
      right: calc(100% + 0.5rem);
      height: 1px;
    }
  }
}
</style>

import { apolloClient as apollo } from '@/apollo';
import { currentMonth, prevMonth, prevOfPrevMonth } from '@/utils/time';

const state = {
  data: [],
  orgCids: {},
};

const getters = {
  GET_CID_DATA: (state) => (cid) => {
    const data = state.data.filter((el) => el.cid == cid) || null;
    return data.length > 0 ? data : null;
  },
  GET_LAST_SIX_MONTHS_DATA: (state) => (cids) => {
    let data = cids.map(
      (cid) => state.data.filter((el) => el.cid == cid) || null
    );

    if (!data.length || !data[0].length) return [null, null, null];

    // Some oragnisations have more than one cids and this is where I deal with it
    data = data.reduce((acc, curr) => {
      curr.forEach((currEl) => {
        const monthIndex = acc.findIndex(
          (el) => el.month_start == currEl.month_start
        );
        if (monthIndex === -1) {
          acc.push(currEl);
        } else {
          const newMonth = { ...acc[monthIndex] };
          for (const key in acc[monthIndex]) {
            if (typeof acc[monthIndex][key] == 'number') {
              const newVal = acc[monthIndex][key] + currEl[key];
              newMonth[key] = newVal;
              acc[monthIndex] = newMonth;
            }
          }
        }
      });
      return acc;
    }, []);

    if (data.length == 0) {
      return null;
    }

    const currMonthData = data.find(
      (el) => new Date(el.month_start).getTime() >= currentMonth.getTime()
    );
    const prevMonthData = data.find(
      (el) => new Date(el.month_start).getTime() == prevMonth.getTime()
    );
    const prevOfPrevMonthData = data.find((el) => {
      return new Date(el.month_start).getTime() == prevOfPrevMonth.getTime();
    });

    return [currMonthData, prevMonthData, prevOfPrevMonthData];
  },
  GET_LAST_SIX_MONTHS_DATA_RAW: (state) => (cids) => {
    let data = cids.map(
        (cid) => state.data.filter((el) => el.cid == cid) || null
    );

    return data;
  },
  GET_CID: (state) => (orgId) => {
    return state.orgCids?.[orgId] || null;
  },
};

const mutations = {
  RESET_STORE: (store) => {
    Object.assign(store, { data: [] });
  },
  SET_MONTHS_DATA: (state, { monthsData }) => {
    monthsData.forEach((el) => {
      if (
        !state.data.find(
          (dataEl) =>
            dataEl.cid == el.cid && dataEl.month_start == el.month_start
        )
      ) {
        state.data.push(el);
      }
    });

    // sort by month start date
    state.data = state.data.sort((a, b) => {
      return (Date.parse(b.month_start) - Date.parse(a.month_start));
    })
  },
  SET_ORG_CIDS: (state, { cids }) => {
    cids.forEach((cidEl) => {
      if (!Object.prototype.hasOwnProperty.call(state.orgCids, cidEl.org_id)) {
        state.orgCids[cidEl.org_id] = [cidEl.member_cid];
      } else {
        state.orgCids[cidEl.org_id].push(cidEl.member_cid);
      }
    });
  },
};

const actions = {
  getCids({ commit, dispatch }, { orgIds }) {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { _cids },
        } = await apollo.query({
          query: require('@/graphql/get_cids.gql'),
          variables: {
            org_ids: orgIds,
          },
        });

        commit('SET_ORG_CIDS', { cids: _cids });

        await dispatch('getAllForLastSixMonths');

        resolve(_cids);
      } catch (e) {
        console.log(e);
        reject();
      }
    });
  },
  getAllForLastSixMonths({ commit }) {
    let fromDate = new Date();
    const date = new Date();
    fromDate.setMonth(fromDate.getMonth() - 5);

    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { _crowdstrike_by_month },
        } = await apollo.query({
          query: require('@/graphql/crowdstrike/get_all_by_date.gql'),
          variables: {
            from_date: new Date(fromDate.getFullYear(), fromDate.getMonth(), 1),
            to_date: new Date(date.getFullYear(), date.getMonth() + 1, 0),
          },
        });
        // console.log("_crowdstrike_by_month", _crowdstrike_by_month)
        commit('SET_MONTHS_DATA', { monthsData: _crowdstrike_by_month });

        resolve();
      } catch (e) {
        console.log(e);
        reject();
      }
    });
  },
  getDataForMonths({ commit }, { cids, monthsNum }) {
    let fromDate = new Date();
    const date = new Date();
    fromDate.setMonth(fromDate.getMonth() - monthsNum + 1);

    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { _crowdstrike_by_month },
        } = await apollo.query({
          query: require('@/graphql/crowdstrike/get_by_date_for_cids.gql'),
          variables: {
            from_date: new Date(fromDate.getFullYear(), fromDate.getMonth(), 1),
            to_date: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            cids,
          },
        });

        commit('SET_MONTHS_DATA', { monthsData: _crowdstrike_by_month });

        resolve();
      } catch (e) {
        console.log(e);
        reject();
      }
    });
  },
  getLatestSixMonths({ commit }, { cids }) {
    // query getMonthsData($cids: [String!], $from_date: date!, $to_date: date!) {

    return new Promise(async (resolve, reject) => {
      const now = new Date();
      const date = new Date();
      date.setMonth(date.getMonth() - 6);
      console.log('from_date', new Date(date.getFullYear(), date.getMonth(), 1))
      console.log('to_date', new Date(now.getFullYear(), now.getMonth() + 1, 0))
      try {
        const {
          data: { _crowdstrike_by_month },
        } = await apollo.query({
          query: require('@/graphql/crowdstrike/get_by_date_for_cids.gql'),
          variables: {
            from_date: new Date(date.getFullYear(), date.getMonth(), 1),
            to_date: new Date(now.getFullYear(), now.getMonth() + 1, 0),
            cids,
          },
        });

        resolve(_crowdstrike_by_month);
      } catch (e) {
        console.log(e);
        reject();
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div
    class="h-16 flex-grow flex flex-col justify-between items-center bg-bgLight border border-gray-600 sticky top-0 z-10"
  >
    <slot />
    <div
      class="flex text-xs justify-between self-stretch border-t border-gray-600"
      :style="'font-size: 0.6rem'"
    >
      <div class="py-1 flex justify-center w-full">
        <p>Oper.</p>
        <BaseTooltip class="ml-1" v-if="infoLeft" :info="infoLeft">
          <font-awesome-icon
            class="text-gray-500"
            :icon="['fa', 'info-circle']"
          />
        </BaseTooltip>
      </div>
      <div class="self-stretch border-l border-gray-600"></div>
      <div class="py-1 flex justify-center w-full">
        <p>Depl.</p>
        <BaseTooltip class="ml-1" v-if="infoRight" :info="infoRight">
          <font-awesome-icon
            class="text-gray-500"
            :icon="['fa', 'info-circle']"
          />
        </BaseTooltip>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTooltip from '@/components/Base/BaseTooltip';

export default {
  props: {
    infoLeft: {
      type: [String, Boolean],
      default: false,
    },
    infoRight: {
      type: [String, Boolean],
      default: false,
    },
  },
  components: {
    BaseTooltip,
  },
};
</script>

import { createRouter, createWebHistory } from 'vue-router';
import { changeSubscriptionToken } from '@/apollo.js';
import store from '../store';
import axios from 'axios';

// Route components
import MainLayout from '@/views/MainLayout.vue';
import Dashboard from '@/views/Dashboard';
import CrowdstrikeViewDetailed from '@/views/CrowdstrikeViewDetailed';
import NetsparkerViewDetailed from '@/views/NetsparkerViewDetailed';
import SpyCloudViewDetailed from '@/views/SpyCloudViewDetailed';
import KnowBe4ViewDetailed from '@/views/KnowBe4ViewDetailed';
import ProofpointViewDetailed from '@/views/ProofpointViewDetailed';
import CiscoViewDetailed from '@/views/CiscoViewDetailed';
import Rapid7ViewDetailed from '@/views/Rapid7ViewDetailed';
import AzureViewDetailed from "@/views/AzureViewDetailed";
import HeatmapView from '@/views/HeatmapView';
import AdminView from '@/views/AdminView';

const routes = [
  {
    path: '/',
    name: 'MainLayout',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: '/heatmap',
        name: 'Heatmap',
        component: HeatmapView,
      },
      {
        path: '/admin',
        name: 'Admin',
        component: AdminView,
      },
      {
        path: '/crowdstrike/:id',
        name: 'CrowdstrikeViewDetailed',
        component: CrowdstrikeViewDetailed,
      },
      {
        path: '/cisco/:id',
        name: 'CiscoViewDetailed',
        component: CiscoViewDetailed,
      },
      {
        path: '/rapid7/:id',
        name: 'Rapid7ViewDetailed',
        component: Rapid7ViewDetailed,
      },
      {
        path: '/netsparker/:id',
        name: 'NetsparkerViewDetailed',
        component: NetsparkerViewDetailed,
      },
      {
        path: '/spycloud/:id',
        name: 'SpyCloudViewDetailed',
        component: SpyCloudViewDetailed,
      },
      {
        path: '/knowbe4/:id',
        name: 'KnowBe4ViewDetailed',
        component: KnowBe4ViewDetailed,
      },
      {
        path: '/proofpoint/:id',
        name: 'ProofpointViewDetailed',
        component: ProofpointViewDetailed,
      },
      {
        path: '/azure/:id',
        name: 'AzureViewDetailed',
        component: AzureViewDetailed,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Login.vue'),
  },
  // {
  //   path: '/facility/:id',
  //   name: 'Facility Usage',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "about" */ '../views/EnergyMonitorFacility.vue'
  //     ),
  // },
];

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name === 'Login') {
    next();
  }

  const token = store.getters['auth/jwt'].jwt_token || null;
  const tokenExp = store.getters['auth/jwt'].jwt_token_expiry || null;

  if (token && tokenExp) {
    const now = new Date().getTime();
    const exp = new Date(tokenExp).getTime() - 1000 * 90;

    if (exp - now < 0) {
      try {
        const refreshToken = await axios.post(
          'https://id.redsmart.app/auth/refresh-token',
          { application: 'enexo' },
          {
            withCredentials: true,
          }
        );
        if (refreshToken.data.jwt_token) {
          changeSubscriptionToken(refreshToken.data.jwt_token);
          store.dispatch('auth/updateJwt', refreshToken.data);
          // store.dispatch('user/setCurrentUser');
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  if (!token)
    if (to.name === 'Login') {
      next();
    } else {
      try {
        const refreshToken = await axios.post(
          'https://id.redsmart.app/auth/refresh-token',
          { application: 'enexo' },
          {
            withCredentials: true,
          }
        );
        if (refreshToken.data.jwt_token) {
          changeSubscriptionToken(refreshToken.data.jwt_token);
          await store.dispatch('auth/updateJwt', refreshToken.data);

          // store.dispatch('user/setCurrentUser');
          next();
        }
      } catch (error) {
        console.log(error);
        next('/login');
      }
    }
  else if (token) {
    return next();
  }
});

export default router;

import { apolloClient as apollo } from '@/apollo';
import crowdstrike from "@/store/modules/crowdstrike";

const defaultStoreState = () => ({
  data: [],
  hierarchy: [],
  selectedHierarchy: [],
  securitySummaryModalInfo : {
    index: null,
    org: {},
    systems: []
  },
  isAggregateMode: false,
  sidebarMenuMode: 'collapse'
});

const state = defaultStoreState();

const getters = {
  GET_TYPE: (state) => (orgId) => {
    if (state.portfolios.find((el) => el.org_id == orgId)) {
      return 'portfolio';
    } else if (state.groups.find((el) => el.org_id == orgId)) {
      return 'group';
    } else {
      return 'vbu';
    }
  },
  GET_ORGANISATION: (state) => (orgId) => {
    return state.data.find((el) => el.org_id == orgId) || null;
  },
  GET_SELECTED_HIERARCHY: (state) => {
    return state.selectedHierarchy;
  },
};

const mutations = {
  RESET_STORE: (store) => {
    Object.assign(store, defaultStoreState());
  },
  SET_STRUCTURE: (state, { structure }) => {
    state.data = structure;
  },
  SELECT_HIERARCHY: (state, { hierarchy }) => {
    state.selectedHierarchy = hierarchy;
  },
  MAKE_HIERARCHY: (state, { structure }) => {
    const levelOne = structure.filter((el) => !el.reports_to);

    state.hierarchy = levelOne.map((el) => structureLevel(el));

    // helpers
    function findChildren(org_id) {
      return structure.filter((el) => el.reports_to === org_id);
    }

    function structureLevel(levelObj) {
      if (findChildren(levelObj.org_id).length == 0) return levelObj;

      const obj = {
        ...levelObj,
        children: findChildren(levelObj.org_id).map((el) => structureLevel(el)),
      };

      return obj;
    }
  },
  SHOW_ALL: (state) => {
    state.selectedHierarchy = state.hierarchy;
  },
  SET_SECURITY_SUMMARY_MODAL_INFO: (state, info) => {
    state.securitySummaryModalInfo.index = info.index;
    state.securitySummaryModalInfo.org = info.org;
    state.securitySummaryModalInfo['systems'][info.system] = info.color;
  },
  SET_AGGREGATE_FOR_HIERARCHY: (state, allStates) => {
    addAggregate(state.hierarchy[0]);
    function addAggregate(obj) {
      if(obj.children) {

        // find VUBs children for Portfolio or Sub-Portfolio or Group
        let VBUsArray = [];
        getObjectVBUs(obj, VBUsArray);

        // calculate actual devices aggregate for Portfolio or Sub-Portfolio or Group
        let VBUsAverage = (VBUsArray.reduce((s, o) => s + Number(o.actual_devices), 0)) / VBUsArray.length;
        obj.actual_devices_aggregate = Number.isInteger(VBUsAverage) ? VBUsAverage : VBUsAverage.toFixed(2);

        // calculate systems aggregate(rapid7, proofpoint, netsparker, ...)
        calculateSystemsAggregate(obj, VBUsArray);

        // console.log("obj", obj)
        // console.log("VBUsArray", VBUsArray)
        // obj.VBUs = VBUsArray;

        // iterate hierarchy nested object
        obj.children.forEach((item) => {
          addAggregate(item)
        })

      }
    }

    function getObjectVBUs(obj, VBUsArray) {
      if(obj.children) {
        obj.children.forEach((item) => {
          getObjectVBUs(item, VBUsArray)
        })
      }

      if(obj.type == 'VBU' && !obj.children) {
        VBUsArray.push({
          org_id: obj.org_id,
          actual_devices: obj.actual_devices
        })
      }
    }

    function calculateSystemsAggregate(obj, VBUsArray) {
      obj.VBUsSystems = {
        azure: [],
        cisco: [],
        knowbe4: [],
        netsparker: [],
        proofpoint: [],
        spycloud: [],
        rapid7: [],
        crowdstrike: [],
        workday: []
      }

      VBUsArray.forEach((item) => {
        obj.VBUsSystems.azure.push({org_id: item.org_id, ...allStates.azure.data[item.org_id]});

        if(allStates.cisco.data[item.org_id]) {
          obj.VBUsSystems.cisco.push({org_id: item.org_id, ...allStates.cisco.data[item.org_id]});
        }

        obj.VBUsSystems.knowbe4.push({org_id: item.org_id, ...allStates.knowbe4.data[item.org_id]});

        if(allStates.netsparker.websitesIds[item.org_id]) {
          obj.VBUsSystems.netsparker.push({...allStates.netsparker.websitesIds[item.org_id]});
        }

        obj.VBUsSystems.proofpoint.push({org_id: item.org_id, ...allStates.proofpoint.data[item.org_id]});

        obj.VBUsSystems.spycloud.push({org_id: item.org_id, ...allStates.spycloud.breaches[item.org_id]});

        if(allStates.rapid7.data[item.org_id]) {
          obj.VBUsSystems.rapid7.push({org_id: item.org_id, ...allStates.rapid7.data[item.org_id]});
        }

        if(allStates.crowdstrike.orgCids[item.org_id]) {
          obj.VBUsSystems.crowdstrike.push(allStates.crowdstrike.orgCids[item.org_id].map(
            (cid) => allStates.crowdstrike.data.filter((el) => el.cid == cid) || null
          ));
        }
      })
    }
  },
  SET_TOGGLE_AGGREGATE_MODE(state) {
    state.isAggregateMode = !state.isAggregateMode;
  },
  SET_EXPAND_COLLAPSE_SIDEBAR_MENU(state, mode) {
    state.sidebarMenuMode = (state.sidebarMenuMode == 'collapse') ? 'expand' : 'collapse';
  }
};

const actions = {
  // change report statement s3_business_travel
  getStructure({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { _organisations },
        } = await apollo.query({
          query: require('@/graphql/get_structure.gql'),
        });

        // console.log("raw(first) structure", _organisations)

        commit('SET_STRUCTURE', { structure: _organisations });
        commit('MAKE_HIERARCHY', { structure: _organisations });

        resolve();
      } catch (e) {
        console.log(e);
        reject();
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import { apolloClient as apollo } from '@/apollo';
// import { currentMonth, prevMonth, prevOfPrevMonth } from '@/utils/time';

const state = {
  data: {},
};

const getters = {};

const mutations = {
  RESET_STORE: (store) => {
    Object.assign(store, { data: [] });
  },
  SET_DATA: (state, { data }) => {
    data.forEach((dataEl) => {
      const dataElementPretty = {
        workday_count: dataEl.emails_aggregate.aggregate.count,
        knowbe4_count: dataEl.knowbe4_user_aggregate.aggregate.count,
      };

      if (!Object.prototype.hasOwnProperty.call(state.data, dataEl.org_id)) {
        state.data[dataEl.org_id] = [dataElementPretty];
      } else {
        state.data[dataEl.org_id].push(dataElementPretty);
      }
    });
  },
};

const actions = {
  getHeatmapData({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { _organisations },
        } = await apollo.query({
          query: require('@/graphql/knowbe4/get_heatmap_data.gql'),
        });

        commit('SET_DATA', { data: _organisations });

        resolve();
      } catch (e) {
        console.log(e);
        reject();
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

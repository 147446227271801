export const normalizeColor = (val, min, max, reversed = false) => {
  let h;
  if (val <= min) {
    h = reversed ? 0 : 120;
    return h;
  }

  if (val >= max) {
    h = reversed ? 120 : 0;
    return h;
  }

  const normValue = val - min;

  if (reversed) {
    h = 0 + normValue * (120 / (max - min));
  } else {
    h = 120 - normValue * (120 / (max - min));
  }

  return h;
};

export const coloursScale = (colorBlind) => {
  if (colorBlind) {
    return [
      '182, 219, 255', // 1
      '109, 182, 255', // 0.7 - 1
      '182, 109, 255', // 0.3, 0.7
      '0, 109, 219', // 0, 0.3
      '73, 0, 146', // 0
    ];
  } else {
    // return [
    //   '109, 200, 34', // 1
    //   '196, 194, 35', // 0.7 - 1
    //   '248, 191, 36', // 0.3, 0.7
    //   '244, 143, 52', // 0, 0.3
    //   '233, 77, 75', // 0
    // ];
    return [
      '117, 226, 70', // 1
      '228, 248, 73', // 0.7 - 1
      '248, 158, 73', // 0.3, 0.7
      '248, 108, 73', // 0, 0.3
      '248, 73, 73', // 0
    ];
  }
};

export const normaliseColour = (
  value,
  reversed = false,
  colorBlind = false
) => {
  if (isNaN(value)) {
    return '100, 100, 100';
  }
  let clours = [...coloursScale(colorBlind)];

  if (reversed) {
    clours = clours.reverse();
  }

  if (value == 0) {
    return clours[0];
  } else if (value > 0 && value < 0.3) {
    return clours[1];
  } else if (value >= 0.3 && value < 0.7) {
    return clours[2];
  } else if (value >= 0.7 && value < 1) {
    return clours[3];
  } else {
    return clours[4];
  }
};

// returns value range 0-1
export const normaliseValue = (val, min, max) => {
  if (val <= min) {
    return 0;
  }

  if (val >= max) {
    return 1;
  }

  return (val - min) / (max - min);
};

export const formatBigNum = (num) => {
  return new Intl.NumberFormat('en-US', {}).format(num);
};

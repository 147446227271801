<template>
  <!-- COLUMN CROWDSRIKE -->
  <div class="w-full">
    <HeatmapHeader
      :infoLeft="'Operational: Average detections per asset'"
      :infoRight="'Deployment: Percentage of assets deployed compared to the recorded value'"
    >
      <img src="@/assets/crowdstrike_logo.svg" class="h-3.5 mt-3.5" />
    </HeatmapHeader>
    <div class="grid grid-cols-2 gap-1">
      <!-- left column -->
      <div class="w-full">
        <div
          v-for="(org, i) in flatStructure"
          :key="i"
          class="heatmap-item"
          :class="i == hoveredRow && 'border border-gray-200'"
          :style="`background-color: rgba(${colorsNumb[i].operational.color}, ${
            i == hoveredRow ? '1' : '0.8'
          })`"
          @mouseover="$emit('onRowHover', i)"
          @mouseleave="$emit('onRowHover', null)"
          @click="heatmapItemClicked(i, colorsNumb[i], org)"
        >
          <p v-show="showValue || i == hoveredRow">
            {{
              colorsNumb[i].operational.value >= 0
                ? colorsNumb[i].operational.value.toFixed(2)
                : '--'
            }}
          </p>
        </div>
      </div>
      <div class="w-full">
        <div
          v-for="(org, i) in flatStructure"
          :key="i"
          class="heatmap-item"
          :class="i == hoveredRow && 'border border-gray-200'"
          :style="`background-color: rgba(${colorsNumb[i].deployment.color}, ${
            i == hoveredRow ? '1' : '0.8'
          })`"
          @mouseover="$emit('onRowHover', i)"
          @mouseleave="$emit('onRowHover', null)"
          @click="heatmapItemClicked(i, colorsNumb[i], org)"
        >
          <p v-show="showValue || i == hoveredRow">
            {{
              colorsNumb[i].deployment.value >= 0
                ? colorsNumb[i].deployment.value + '%'
                : '--'
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeatmapHeader from '@/components/HeatmapHeader';

import { normaliseValue, normaliseColour } from '@/utils/utils';

export default {
  components: {
    HeatmapHeader,
  },
  props: {
    flatStructure: {
      type: Array,
      required: true,
    },
    hoveredRow: {
      type: [Number, null],
      default: null,
    },
    clickedRow: {
      type: [Number, null],
      default: null,
    },
    showValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colorsNumb() {
      return this.flatStructure.map((el) => {
        const cid = this.$store.getters['crowdstrike/GET_CID'](el.org_id);

        // const orgWebsites = this.$store.state.crowdstrike.data[el.org_id] || [];
        // const scans = orgWebsites.flatMap((el) => el.scan_details);

        if (cid) {
          // const data = this.$store.getters['crowdstrike/GET_LAST_SIX_MONTHS_DATA'](cid);
          const data = this.$store.getters['crowdstrike/GET_LAST_SIX_MONTHS_DATA_RAW'](cid);

          if (data) {
            const currMonthData = (data[0] && data[0][0]) ? data[0][0] : {};
            const prevMonthData = (data[0] && data[0][1]) ? data[0][1] : {};

            const operationalVal =
              prevMonthData?.devices > 0
                ? prevMonthData?.detections / prevMonthData?.devices
                : NaN;
            let deployVal =
              el.actual_devices > 0
                ? currMonthData?.devices / el.actual_devices
                : NaN;

            let deployValNormalise;
            switch (true) {
              case deployVal <= 0.2:
                deployValNormalise = 0;
                break;
              case deployVal > 0.2 && deployVal <= 0.4:
                deployValNormalise = 0.1;
                break;
              case deployVal > 0.4 && deployVal <= 0.6:
                deployValNormalise = 0.4;
                break;
              case deployVal > 0.6 && deployVal <= 0.8:
                deployValNormalise = 0.8;
                break;
              case deployVal > 0.8:
                deployValNormalise = 1;
                break;
            }

            return {
              operational: {
                value: operationalVal,
                color: isNaN(operationalVal) ? NaN : this.normaliseColour(
                  this.normaliseValue(operationalVal, 0, 0.3),
                  false,
                  this.colorBlindMode
                ),
              },
              deployment: {
                value: Math.round(deployVal * 100),
                color: isNaN(deployVal) ? NaN : this.normaliseColour(
                  deployValNormalise,
                  true,
                  this.colorBlindMode
                ),
              },
            };
          }
        }

        return {
          operational: { color: NaN, value: '-' },
          deployment: { color: NaN, value: '-' },
        };
      });
    },
    colorBlindMode() {
      return this.$store.state.ui.colorblindMode;
    },
  },
  methods: {
    normaliseValue,
    normaliseColour,
    heatmapItemClicked(i, color, org) {
      this.$emit('onRowClick', i)

      this.$store.commit('structure/SET_SECURITY_SUMMARY_MODAL_INFO', {
        system: 'crowdstrike',
        index: i,
        color: color,
        org: org
      })

      this.$vfm.show('security-summary-modal');
    }
  },
  watch: {
    clickedRow(clickedRowIndex) {
      // console.log("clickedRowIndex", clickedRowIndex)
      this.$store.commit('structure/SET_SECURITY_SUMMARY_MODAL_INFO', {
        system: 'crowdstrike',
        index: clickedRowIndex,
        color: this.colorsNumb[clickedRowIndex],
        org: this.flatStructure[clickedRowIndex]
      })
    }
  }
};
</script>

<style lang="scss" scoped></style>

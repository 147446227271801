<template>
  <!-- COLUMN Netsparker -->
  <div class="w-full">
    <HeatmapHeader
      :infoLeft="'Operational: Percentage of users that have completed awareness training'"
      :infoRight="'Deployment: Grey for not using Knowbe4, green for complete deployment going red as the percentage of users goes lower'"
    >
      <img src="@/assets/knowbe4-logo.png" class="h-3 mt-3" />
    </HeatmapHeader>
    <div class="grid grid-cols-2 gap-1">
      <!-- left column -->
      <div class="w-full">
        <div
          v-for="(org, i) in flatStructure"
          :key="i"
          class="heatmap-item"
          :class="i == hoveredRow && 'border border-gray-200'"
          :style="`background-color: rgba(${colorsNumb[i].operational.color}, ${
            i == hoveredRow ? '1' : '0.8'
          })`"
          @mouseover="$emit('onRowHover', i)"
          @mouseleave="$emit('onRowHover', null)"
          @click="heatmapItemClicked(i, colorsNumb[i], org)"
        >
          <p v-show="showValue || i == hoveredRow">
            {{
              colorsNumb[i].operational.value >= 0
                ? colorsNumb[i].operational.value.toFixed(2)
                : '--'
            }}
          </p>
        </div>
      </div>
      <div class="w-full">
        <div
          v-for="(org, i) in flatStructure"
          :key="i"
          class="heatmap-item"
          :class="i == hoveredRow && 'border border-gray-200'"
          :style="`background-color: rgba(${colorsNumb[i].deployment.color}, ${
            i == hoveredRow ? '1' : '0.8'
          })`"
          @mouseover="$emit('onRowHover', i)"
          @mouseleave="$emit('onRowHover', null)"
          @click="heatmapItemClicked(i, colorsNumb[i], org)"
        >
          <p v-show="showValue || i == hoveredRow">
            {{
              colorsNumb[i].deployment.value >= 0
                ? colorsNumb[i].deployment.value + '%'
                : '--'
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeatmapHeader from '@/components/HeatmapHeader';

import { normaliseValue, normaliseColour } from '@/utils/utils';

export default {
  components: {
    HeatmapHeader,
  },
  props: {
    flatStructure: {
      type: Array,
      required: true,
    },
    hoveredRow: {
      type: [Number, null],
      default: null,
    },
    clickedRow: {
      type: [Number, null],
      default: null,
    },
    showValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colorsNumb() {
      return this.flatStructure.map((el) => {
        const knowBe4Data = this.$store.state.knowbe4.data[el.org_id] || [];

        if (knowBe4Data.length) {
          // const [currMonthData, prevMonthData] =
          //   this.$store.getters['crowdstrike/GET_LAST_THREE_MONTHS_DATA'](cid);
          let dataReduced;
          if (knowBe4Data.length > 1) {
            dataReduced = knowBe4Data.reduce((acc, curr) => {
              for (const key in curr) {
                if (!Object.prototype.hasOwnProperty.call(acc, key)) {
                  acc[key] = curr[key];
                } else {
                  acc[key] += curr[key];
                }
              }
              return acc;
            }, {});
          } else {
            dataReduced = knowBe4Data[0];
          }

          const operationalVal = 0;
          const deployVal =
            dataReduced.workday_count == 0
              ? 0
              : dataReduced.knowbe4_count / dataReduced.workday_count;
          // console.log(operationalVal)

          return {
            // TODO
            // operational: {
            //   value: operationalVal,
            //   color: this.normaliseColour(
            //     this.normaliseValue(operationalVal, 0, 0.3),
            //     false,
            //     this.colorBlindMode
            //   ),
            // },
            operational: { color: NaN, value: '-' },
            deployment: {
              value: Math.round(deployVal * 100),
              color: this.normaliseColour(
                this.normaliseValue(deployVal, 0, 1),
                true,
                this.colorBlindMode
              ),
            },
          };
        }

        return {
          operational: { color: NaN, value: '-' },
          deployment: { color: NaN, value: '-' },
        };
      });
    },
    colorBlindMode() {
      return this.$store.state.ui.colorblindMode;
    },
  },
  methods: {
    normaliseValue,
    normaliseColour,
    heatmapItemClicked(i, color, org) {
      this.$emit('onRowClick', i)

      this.$store.commit('structure/SET_SECURITY_SUMMARY_MODAL_INFO', {
        system: 'knowbe4',
        index: i,
        color: color,
        org: org
      })

      this.$vfm.show('security-summary-modal');
    }
  },
  watch: {
    clickedRow(clickedRowIndex) {
      this.$store.commit('structure/SET_SECURITY_SUMMARY_MODAL_INFO', {
        system: 'knowbe4',
        index: clickedRowIndex,
        color: this.colorsNumb[clickedRowIndex],
        org: this.flatStructure[clickedRowIndex]
      })
    }
  }
};
</script>

<style lang="scss" scoped></style>

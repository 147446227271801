import { apolloClient as apollo } from '@/apollo';
import { currentMonth, prevMonth, prevOfPrevMonth } from '@/utils/time';

const state = {
  data: {},
  // ^ above:
  // org_id: {...data}
};

const getters = {};

const mutations = {
  RESET_STORE: (store) => {
    Object.assign(store, { data: [] });
  },
  SET_DATA: (state, { data }) => {
    data.forEach((dataEl) => {
      if (dataEl.emails_num.aggregate.count == 0) return;

      const dataElementPretty = {
        emails_count: dataEl.emails_num.aggregate.count,
        top_clickers: dataEl.top_clickers.aggregate.count,
        vaps: dataEl.vaps.aggregate.count,
        blocked_clicks_last_month:
          dataEl.blocked_clicks_last_month.aggregate.count,
        blocked_clicks_this_month:
          dataEl.blocked_clicks_this_month.aggregate.count,
        blocked_clicks_prevofprev_month:
          dataEl.blocked_clicks_prevofprev_month.aggregate.count,
      };

      if (!Object.prototype.hasOwnProperty.call(state.data, dataEl.org_id)) {
        state.data[dataEl.org_id] = [dataElementPretty];
      } else {
        state.data[dataEl.org_id].push(dataElementPretty);
      }
    });
  },
};

const actions = {
  getAllData({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { _organisations },
        } = await apollo.query({
          query: require('@/graphql/proofpoint/get_all_data.gql'),
          variables: {
            currentMonth,
            prevMonth,
            prevOfPrevMonth,
          },
        });

        commit('SET_DATA', { data: _organisations });

        resolve();
      } catch (e) {
        console.log(e);
        reject();
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

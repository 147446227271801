import { apolloClient as apollo } from '@/apollo';
import { currentMonth, prevMonth, prevOfPrevMonth } from '@/utils/time';

const state = {
  isActive: {},
  breaches: {},
};

const getters = {};

const mutations = {
  RESET_STORE: (store) => {
    Object.assign(store, { data: [] });
  },
  SET_BREACHES: (state, { breaches }) => {
    breaches.forEach((breachEl) => {
      if (breachEl.emails_num.aggregate.count == 0) return;

      const dataElementPretty = {
        emails_count: breachEl.emails_num.aggregate.count,
        curr_breaches_num: breachEl.curr_breaches_num.aggregate.count,
        prev_breaches_num: breachEl.prev_breaches_num.aggregate.count,
        prev_of_prev_breaches_num:
          breachEl.prev_of_prev_breaches_num.aggregate.count,
      };

      if (
        !Object.prototype.hasOwnProperty.call(state.breaches, breachEl.org_id)
      ) {
        state.breaches[breachEl.org_id] = [dataElementPretty];
      } else {
        state.breaches[breachEl.org_id].push(dataElementPretty);
      }
    });
  },
  SET_ACTIVE(state, { organisations }) {
    organisations.forEach((org) => {
      state.isActive[org.org_id] = org.active;
    });
  },
};

const actions = {
  getAllBreaches({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { _organisations },
        } = await apollo.query({
          query: require('@/graphql/spycloud/get_all_breaches.gql'),
          variables: {
            currentMonth,
            prevMonth,
            prevOfPrevMonth,
          },
        });

        // console.log("_organisations", _organisations)

        commit('SET_BREACHES', { breaches: _organisations });

        resolve();
      } catch (e) {
        console.log(e);
        reject();
      }
    });
  },
  getHeatmapData({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { _spycloud_active },
        } = await apollo.query({
          query: require('@/graphql/spycloud/get_heatmap_data.gql'),
        });
        commit('SET_ACTIVE', { organisations: _spycloud_active });

        resolve();
      } catch (e) {
        console.log(e);
        reject();
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <router-link
    class="card flex flex-col"
    :class="{'cursor-auto': !orgData.websites_number}"
    :to="orgData.websites_number > 0 ? `/netsparker/${org.org_id}` : '#'"
  >
    <!-- :to="`/crowdstrike/${vbu.name.replaceAll(' ', '_')}-&-${'vbu.id' || '#'}`" -->
    <div
      class="flex justify-between h-12 items-center bg-bgLighter border-b border-gray-700 px-6 py-3 text-gray-300 card--title"
      :class="this.org && 'clickable'"
    >
      <div>
        <img src="@/assets/invicti_logo.png" class="inline-block h-6" />
        <BaseTooltip
          class="ml-2 inline-block"
          :info="'Web Application Security'"
        >
          <font-awesome-icon
            class="text-gray-500"
            :icon="['fa', 'info-circle']"
          />
        </BaseTooltip>
      </div>
      <font-awesome-icon
        v-if="orgData.website_id"
        class="text-gray-700 text-xl transform -rotate-45 icon transition-colors"
        :icon="['fa', 'arrow-right']"
      />
    </div>
    <div class="grid grid-cols-3 h-full" v-if="(orgData.website_id && !isAggregateMode) || isAggregateMode">
      <div class="col-span-2 flex items-center border-r border-gray-700">
        <div class="flex flex-col items-center px-6 flex-grow">
          <div class="flex items-center">
            <font-awesome-icon
              class="text-xl mr-2 text-blue-500"
              :icon="['fa', 'calendar-day']"
            />
            <h2 class="text-4xl" v-if="isAggregateMode && org.type != 'VBU'">{{ orgDataAggregate.last_scan_day || '-' }}</h2>
            <h2 class="text-4xl" v-else>{{ orgData.last_scan_day || '-' }}</h2>
          </div>
          <p class="text-sm text-gray-500 text-center">
            day(s) since last scan
          </p>
        </div>
        <div class="self-stretch border-l border-gray-700"></div>
        <div class="flex flex-col items-center px-6">
          <div class="flex items-center">
            <font-awesome-icon
              class="text-xl mr-2 text-yellow-500"
              :icon="['fa', 'exclamation-triangle']"
            />
            <h2 class="text-4xl" v-if="isAggregateMode && org.type != 'VBU'">{{ orgDataAggregate.outsanding_issues || '-' }}</h2>
            <h2 class="text-4xl" v-else>{{ orgData.outsanding_issues || '-' }}</h2>
          </div>
          <p class="text-sm text-gray-500 text-center">
            outstanding<br />issues
          </p>
        </div>
      </div>

      <div class="flex flex-col">
        <div class="p-3 border-b border-gray-700">
          <div
            class="flex items-center justify-center"
            :class="
              orgData.status == 'Complete'
                ? 'text-green-500'
                : orgData.status == 'Failed'
                ? 'text-red-500'
                : 'text-yellow-500'
            "
          >
            <font-awesome-icon
              class="text-sm mr-2"
              :icon="[
                'fa',
                `${
                  orgData.status == 'Complete'
                    ? 'check-circle'
                    : orgData.status == 'Failed'
                    ? 'times-circle'
                    : 'exclamation-circle'
                }`,
              ]"
            />
            <h4 class="uppercase">{{ orgData.status || '-' }}</h4>
          </div>
          <p class="text-xs text-gray-500 text-center">scan status</p>
        </div>
        <div class="p-3">
          <div class="flex items-center justify-center">
            <font-awesome-icon
              class="text-yellow-400 text-sm mr-2"
              :icon="['fa', 'calendar-day']"
            />
            <h4 v-if="isAggregateMode && org.type != 'VBU'">
              {{ orgDataAggregate.oldest_issue }}
              <span class="text-xs text-gray-400 font-normal">day(s)</span>
            </h4>
            <h4 v-else>
              {{ orgData.oldest_issue }}
              <span class="text-xs text-gray-400 font-normal">day(s)</span>
            </h4>
          </div>
          <p class="text-xs text-gray-500 text-center">oldest issue</p>
        </div>
      </div>
      <!-- <div class="p-3 flex flex-col justify-center items-center w-2/5">

      </div> -->
    </div>
    <div class="flex items-center justify-center h-full" v-else>
      <p class="py-8 opacity-50">No website assigned</p>
    </div>
  </router-link>
</template>

<script>
import BaseTooltip from '@/components/Base/BaseTooltip';

export default {
  components: {
    BaseTooltip,
  },
  props: {
    org: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isAggregateMode() {
      return this.$store.state.structure.isAggregateMode;
    },
    orgData() {
      const dataObj = {
        last_scan_day: null,
        status: null,
        websites_number: 0,
        oldest_issue: null,
        website_id: null,
        outsanding_issues: 0,
      };
      const orgWebsites =
        this.$store.state.netsparker.websitesIds[this.org.org_id] || [];
        // console.log("orgWebsites", orgWebsites)
      if (orgWebsites.length) {
        dataObj.website_id = orgWebsites[0].website_id;
        dataObj.websites_number = orgWebsites.length;

        // Oldest issue
        orgWebsites.forEach((el) => {
          if (
            dataObj.oldest_issue &&
            el.issues.length &&
            new Date(el.issues[0].first_seen).getTime() <
              new Date(dataObj.oldest_issue).getTime()
          ) {
            dataObj.oldest_issue = new Date(el.issues[0].first_seen);
          } else if (el.issues.length && !dataObj.oldest_issue) {
            dataObj.oldest_issue = el.issues[0].first_seen;
          }
        });

        if (dataObj.oldest_issue) {
          dataObj.oldest_issue = Math.floor(
            (new Date().getTime() - new Date(dataObj.oldest_issue).getTime()) /
              (1000 * 3600 * 24)
          );
        }

        // outstanding issue
        dataObj.outsanding_issues = orgWebsites.reduce((acc, curr) => {
          return (
            acc + curr.scan_details[0]?.issues_aggregate.aggregate.count || 0
          );
        }, 0);

        if (orgWebsites[0].scan_details.length) {
          dataObj.last_scan_day = Math.floor(
            (new Date().getTime() -
              new Date(orgWebsites[0].scan_details[0].scan_date).getTime()) /
              (1000 * 3600 * 24)
          );
          dataObj.status = orgWebsites[0].scan_details[0].state;
        }
      }

      // console.log("dataObj", dataObj)

      return dataObj;
    },
    orgDataAggregate() {
      let netsparkerList = this.org.VBUsSystems.netsparker;
      let netsparkerListFlat = [];
      const dataObj = {
        last_scan_day: null,
        oldest_issue: null,
        outsanding_issues: 0,
      };

      if(this.org.VBUsSystems && netsparkerList[0]) {
        for(let i in netsparkerList) {
          for(let j in netsparkerList[i]) {
            netsparkerListFlat.push(netsparkerList[i][j]);
          }
        }

        // oldest issue
        netsparkerListFlat.forEach((el) => {
          if (
            dataObj.oldest_issue &&
            el.issues.length &&
            new Date(el.issues[0].first_seen).getTime() <
            new Date(dataObj.oldest_issue).getTime()
          ) {
            dataObj.oldest_issue = new Date(el.issues[0].first_seen);
          } else if (el.issues.length && !dataObj.oldest_issue) {
            dataObj.oldest_issue = el.issues[0].first_seen;
          }
        });
        if (dataObj.oldest_issue) {
          dataObj.oldest_issue = Math.floor(
            (new Date().getTime() - new Date(dataObj.oldest_issue).getTime()) /
            (1000 * 3600 * 24)
          );
        }

        // outstanding issue
        let outstandingIssuesAverage = (netsparkerListFlat.reduce((acc, curr) => {
          return (
            acc + curr.scan_details[0]?.issues_aggregate.aggregate.count || 0
          );
        }, 0)) / netsparkerListFlat.length;
        dataObj.outsanding_issues = Number.isInteger(outstandingIssuesAverage) ? outstandingIssuesAverage : Number(outstandingIssuesAverage.toFixed(2));

        // last scan day
        let lastScanDayList = [];
        netsparkerListFlat.forEach((el) => {
          if (el.scan_details.length) {
            lastScanDayList.push(Math.floor(
              (new Date().getTime() -
                new Date(el.scan_details[0].scan_date).getTime()) /
              (1000 * 3600 * 24)
            ));
          }
        });
        let lastScanDayListAverage = (lastScanDayList.reduce((sum, current) => sum += current) || 0) / lastScanDayList.length;
        dataObj.last_scan_day = Number.isInteger(lastScanDayListAverage) ? lastScanDayListAverage : Number(lastScanDayListAverage.toFixed(2));

        // console.log("lastScanDayList", lastScanDayList)
        // console.log("dataObj", dataObj)
        // console.log("netsparkerList", netsparkerList)
        // console.log("netsparkerListFlat", netsparkerListFlat)

        return dataObj;
      }

      return dataObj;
    },
  }
};
</script>

<style lang="scss" scoped>
.card {
  &--title {
    @apply transition-colors duration-300 relative;
  }

  &:hover {
    .card--title.clickable {
      background-color: rgba(52, 52, 62, 1);

      .icon {
        @apply text-gray-400;
      }
    }
  }
}
</style>

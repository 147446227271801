<template>
  <router-link
    class="card flex flex-col"
    :class="{'cursor-auto': !blocksData}"
    :to="blocksData ? `/rapid7/${org.org_id}` : '#'"
  >
    <div
      class="flex justify-between h-12 items-center bg-bgLighter border-b border-gray-700 px-6 py-3 text-gray-300 card--title"
      :class="blocksData && 'clickable'"
    >
      <div>
        <img src="@/assets/rapid7_logo.svg" class="h-4 inline-block" />
        <BaseTooltip
          class="ml-2 inline-block"
          :info="'Vulnerability Management'"
        >
          <font-awesome-icon
            class="text-gray-500"
            :icon="['fa', 'info-circle']"
          />
        </BaseTooltip>
      </div>
      <font-awesome-icon
        v-if="blocksData"
        class="text-gray-700 text-xl transform -rotate-45 icon transition-colors"
        :icon="['fa', 'arrow-right']"
      />
    </div>
    <div
      class="w-full h-full flex items-center border-r border-gray-700"
      v-if="(blocksData && !isAggregateMode) || isAggregateMode"
    >
      <div class="flex flex-col items-center px-6 py-4 w-full">
        <div class="flex items-center">
          <!-- <font-awesome-icon
            class="text-xl mr-2"
            :class="
              orgData.prev_month_blocks > orgData.prevofprev_month_blocks
                ? 'text-red-500'
                : 'text-green-500'
            "
            :icon="[
              'fa',
              `arrow-alt-circle-${
                orgData.prev_month_blocks > orgData.prevofprev_month_blocks
                  ? 'up'
                  : 'down'
              }`,
            ]"
          /> -->
          <font-awesome-icon
            class="text-xl mr-2 text-yellow-500"
            :icon="['fa', 'exclamation-triangle']"
          />
          <h2 class="text-4xl" v-if="isAggregateMode && org.type != 'VBU'">
            {{
              orgDataAggregate.assets
                ? formatBigNum(Math.round(orgDataAggregate.risk_score / orgDataAggregate.assets))
                : 0
            }}
          </h2>
          <h2 class="text-4xl" v-else>
            {{
              orgData.assets
                ? formatBigNum(Math.round(orgData.risk_score / orgData.assets))
                : 0
            }}
          </h2>
        </div>
        <p class="text-sm text-gray-500 text-center">risk score per asset</p>
      </div>
      <div class="self-stretch border-l border-gray-700"></div>
      <div class="flex flex-col items-center justify-center h-full px-6 w-full">
        <div class="flex items-center">
          <font-awesome-icon
            class="text-xl mr-2 text-indigo-500"
            :icon="['fa', 'server']"
          />
          <h2 class="text-4xl" v-if="isAggregateMode && org.type != 'VBU'">{{ orgDataAggregate.assets }}</h2>
          <h2 class="text-4xl" v-else>{{ orgData.assets }}</h2>
        </div>
        <p class="text-sm text-gray-500 text-center">assets</p>
      </div>
    </div>
    <div class="flex items-center justify-center h-full" v-else>
      <p class="py-8 opacity-50">No rapid7 account connected.</p>
    </div>
  </router-link>
</template>

<script>
import BaseTooltip from '@/components/Base/BaseTooltip';
import { formatBigNum } from '@/utils/utils';

export default {
  components: {
    BaseTooltip,
  },
  props: {
    org: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isAggregateMode() {
      return this.$store.state.structure.isAggregateMode;
    },
    orgData() {
      let dataObj = {
        assets: 0,
        risk_score: 0,
      };

      if (this.blocksData) {
        // Aggregates data in case there's more than one email_org<=>org_id connections
        dataObj.assets = this.blocksData.assets;
        dataObj.risk_score = this.blocksData.risk_score;
      }

      return dataObj;
    },
    orgDataAggregate() {
      let rapid7List = this.org.VBUsSystems.rapid7;
      
      if(this.org.VBUsSystems && rapid7List.length) {
        let sumVBUs = rapid7List.reduce((sumObj, currentObj) => {
          return {
            assets: (sumObj.assets || 0) + Number(currentObj?.assets || 0),
            risk_score: (sumObj.risk_score || 0) + Number(currentObj?.risk_score || 0),
          }
        }, 0)

        return {
          assets: Number.isInteger(sumVBUs.assets / rapid7List.length) ?
            (sumVBUs.assets / rapid7List.length) : (sumVBUs.assets / rapid7List.length).toFixed(2),
          risk_score: Number.isInteger(sumVBUs.risk_score / rapid7List.length) ?
            (sumVBUs.risk_score / rapid7List.length) : (sumVBUs.risk_score / rapid7List.length).toFixed(2),
        };
      } else {
        return {}
      }
    },
    blocksData() {
      return this.$store.state.rapid7.data[this.org.org_id] || null;
    },
  },
  methods: {
    formatBigNum,
  }
};
</script>

<style lang="scss" scoped>
.card {
  &--title {
    @apply transition-colors duration-300 relative;
  }

  &:hover {
    .card--title.clickable {
      background-color: rgba(52, 52, 62, 1);

      .icon {
        @apply text-gray-400;
      }
    }
  }
}
</style>

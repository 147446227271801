<template>
  <div>
    <div class="main-header flex px-8 border-b border-gray-700 bg-bgDarkest">
      <router-link to="/" class="my-6">
        <img
          src="@/assets/datamango-logo.png"
          class="h-7"
          alt=""
          v-if="this.$dbEndpoint == 'demo'"
        />
        <img src="@/assets/volaris_logo.png" class="h-8" alt="" v-else />
      </router-link>

      <nav class="mx-auto self-end">
        <ul class="flex gap-x-8">
          <li><router-link to="/">Dashboard</router-link></li>
          <li><router-link to="/heatmap">Heatmap</router-link></li>
          <li><router-link to="/admin">Admin</router-link></li>
        </ul>
      </nav>
    </div>
    <button
      class="sidebar-toggle-button self-end w-10 h-10 rounded-full text-sm mb-0.5 fixed top-6 right-8 z-30"
      :class="
        showSidebar
          ? 'bg-red-500 hover:bg-red-600'
          : 'bg-purple-500 hover:bg-purple-600'
      "
      @click="showSidebar = !showSidebar"
    >
      <font-awesome-icon v-if="!showSidebar" :icon="['fa', 'filter']" />
      <font-awesome-icon v-else :icon="['fa', 'times']" />
    </button>
    <HierarchySidebar
      class="fixed h-screen top-0 right-0 overflow-x-hidden overflow-y-auto p-12 bg-bgLight border-l border-gray-700 transform transition-transform duration-500 ease-in-out z-20"
      :class="!showSidebar && 'translate-x-full'"
      @close="showSidebar = false"
    />
    <div class="p-8 w-full" v-if="!isLoading">
      <router-view />
    </div>
    <div class="w-10 h-10 mx-auto my-12" v-else>
      <SpinnerBase />
    </div>
  </div>
</template>

<script>
import HierarchySidebar from '@/components/HierarchySidebar';
import SpinnerBase from '@/components/Base/SpinnerBase';

export default {
  name: 'Home',
  components: {
    HierarchySidebar,
    SpinnerBase,
  },
  data() {
    return {
      showSidebar: false,
      isLoading: true,
    };
  },
  methods: {
    changeView(view) {
      if (view == 'Dashboard' && this.$route.path !== '/') {
        this.$router.push('/');
      } else if (view == 'Heat Map' && this.$route.path !== '/crowdstrike') {
        this.$router.push('/heatmap');
      }
    },
  },
  async mounted() {
    if (!this.$store.state.structure?.data.length) {
      await this.$store.dispatch('structure/getStructure');
    }

    const promises = [];
    // CROWDSRIKE DATA
    if (
      !this.$store.state.crowdstrike.orgCids.length &&
      !this.$store.state.crowdstrike.data.length
    ) {
      promises.push(
        this.$store.dispatch('crowdstrike/getCids', {
          orgIds: this.$store.state.structure.data.map((el) => el.org_id),
        })
      );
    }
    if (!this.$store.state.netsparker.websitesIds.length) {
      promises.push(this.$store.dispatch('netsparker/getOrgWebsites'));
      // get data for fetched websites
    }
    if (!Object.keys(!this.$store.state.spycloud.breaches).length) {
      promises.push(this.$store.dispatch('spycloud/getAllBreaches'));
    }
    if (!Object.keys(!this.$store.state.spycloud.isActive).length) {
      promises.push(this.$store.dispatch('spycloud/getHeatmapData'));
    }
    if (!Object.keys(!this.$store.state.proofpoint.data).length) {
      promises.push(this.$store.dispatch('proofpoint/getAllData'));
    }
    if (!Object.keys(!this.$store.state.knowbe4.data).length) {
      promises.push(this.$store.dispatch('knowbe4/getHeatmapData'));
    }
    if (!Object.keys(!this.$store.state.cisco.data).length) {
      promises.push(this.$store.dispatch('cisco/getAllData'));
    }
    if (!Object.keys(!this.$store.state.rapid7.data).length) {
      promises.push(this.$store.dispatch('rapid7/getAllData'));
    }
    if (!Object.keys(!this.$store.state.azure.data).length) {
      promises.push(this.$store.dispatch('azure/getAllData'));
    }

    await Promise.all(promises);

    await this.$store.commit('structure/SET_AGGREGATE_FOR_HIERARCHY', this.$store.state);

    this.isLoading = false;
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fadeQuick-enter-active,
.fadeQuick-leave-active {
  transition: opacity 0.25s ease-in-out;
}

.fadeQuick-enter-from,
.fadeQuick-leave-to {
  opacity: 0;
}

nav {
  li {
    a {
      @apply block text-gray-500 pb-4 pt-2 px-3 border-b-2 border-transparent transition-colors;

      &.router-link-exact-active {
        @apply border-indigo-500 text-gray-300;
      }

      &:hover:not(.router-link-exact-active) {
        @apply border-gray-500 text-gray-400;
      }
    }
  }
}
</style>

<template>
  <router-link
    class="card"
    :class="{'cursor-auto': !orgData.isClickable}"
    :to="orgData.isClickable ? `/crowdstrike/${org.org_id}` : '#'"
    @click="isClicked = true"
  >
    <!-- :to="`/crowdstrike/${vbu.name.replaceAll(' ', '_')}-&-${'vbu.id' || '#'}`" -->
    <div
      class="flex justify-between h-12 items-center bg-bgLighter border-b border-gray-700 px-6 py-3 text-gray-300 card--title"
      :class="this.org && 'clickable'"
    >
      <div>
        <img src="@/assets/crowdstrike_logo.svg" class="h-6 inline-block"/>
        <BaseTooltip class="ml-2 inline-block" :info="'Endpoint Security'">
          <font-awesome-icon
            class="text-gray-500"
            :icon="['fa', 'info-circle']"
          />
        </BaseTooltip>
      </div>
      <div class="flex items-center">
        <div class="not-clickable-warning text-primary text-sm mr-3" v-if="!orgData.isClickable && isClicked">
          <font-awesome-icon
            class="text-primary mr-1"
            :icon="['fa', 'info-circle']"
          />
          <span>No click-through data</span>
        </div>
        <font-awesome-icon
          v-if="orgData.id"
          class="text-gray-700 text-xl transform -rotate-45 icon transition-colors"
          :icon="['fa', 'arrow-right']"
        />
      </div>

    </div>
    <div class="grid grid-cols-3" v-if="orgData.id">
      <div
        class="col-span-2 flex flex-col items-center justify-center border-r border-gray-700"
      >
        <div class="flex items-center">
          <font-awesome-icon
            v-if="isDetectionHigher !== null"
            class="text-xl mr-2"
            :class="isDetectionHigher ? 'text-red-500' : 'text-green-500'"
            :icon="[
              'fa',
              `arrow-alt-circle-${isDetectionHigher ? 'up' : 'down'}`,
            ]"
          />
          <h2 class="text-4xl" v-if="isAggregateMode && org.type != 'VBU'">
            {{ orgDataAggregate.prev_month_detections }}
          </h2>
          <h2 class="text-4xl" v-else>
            {{ orgData.prev_month_detections }}
          </h2>
        </div>
        <p class="text-sm text-gray-500">last month detections</p>
      </div>
      <div class="flex flex-col">
        <div class="p-3 border-b border-gray-700">
          <div class="flex items-center justify-center">
            <font-awesome-icon
              class="text-purple-400 text-sm mr-2"
              :icon="['fa', 'search']"
            />
            <h4 v-if="isAggregateMode && org.type != 'VBU'">{{ orgDataAggregate.detections }}</h4>
            <h4 v-else>{{ orgData.detections }}</h4>
          </div>
          <p class="text-xs text-gray-500 text-center">detections</p>
        </div>
        <div class="p-3">
          <div class="flex items-center justify-center">
            <font-awesome-icon
              class="text-yellow-400 text-sm mr-2"
              :icon="['fa', 'server']"
            />
            <h4 v-if="isAggregateMode && org.type != 'VBU'">{{ orgDataAggregate.assets }}</h4>
            <h4 v-else>{{ orgData.assets }}</h4>
          </div>
          <p class="text-xs text-gray-500 text-center">assets</p>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-center" v-else>
      <p class="py-8 opacity-50">
        We don't have any data for this organisation.
      </p>
    </div>
  </router-link>
</template>

<script>
import BaseTooltip from '@/components/Base/BaseTooltip';

export default {
  components: {
    BaseTooltip,
  },
  data() {
    return {
      isClicked: false
    }
  },
  props: {
    org: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isAggregateMode() {
      return this.$store.state.structure.isAggregateMode;
    },
    orgData() {
      let vbuObject = {
        detections: 0,
        assets: 0,
        id: null,
        isClickable: false
      };
      const cid = this.$store.getters['crowdstrike/GET_CID'](this.org.org_id);


      if (cid) {
        let sixMonthData = this.$store.getters['crowdstrike/GET_LAST_SIX_MONTHS_DATA'](cid);
        const sixMonthDataRaw = this.$store.getters['crowdstrike/GET_LAST_SIX_MONTHS_DATA_RAW'](cid);
        // check if organization is clickable or not
        let isClickable = false;
        sixMonthDataRaw.forEach((item) => {
          for (let i in item) {
            if (item[i].low || item[i].medium || item[i].high || item[i].critial) {
              isClickable = true;
              break;
            }
          }
        })

        if(sixMonthDataRaw[0]) {
          vbuObject = {
            detections: sixMonthDataRaw[0][0]?.detections || 0,
            assets: sixMonthDataRaw[0][0]?.devices || 0,
            prev_month_detections: sixMonthDataRaw[0][1]?.detections || 0,
            prev_of_prev_month_detections: sixMonthDataRaw[0][2]?.detections || 0,
            id: cid,
            org_id: this.org.org_id,
            isClickable: isClickable
          };
        }
      }

      return vbuObject;
    },
    orgDataAggregate() {
      let crowdstrikeList = this.org.VBUsSystems.crowdstrike;

      if (this.org.VBUsSystems && crowdstrikeList.length) {

        let dataArr = [];
        crowdstrikeList.forEach((item) => {
          let sortedArray = item[0].sort((a, b) => {
            return (Date.parse(b.month_start) - Date.parse(a.month_start));
          })

          dataArr.push({
            detections: sortedArray[0]?.detections || 0,
            assets: sortedArray[0]?.devices || 0,
            prev_month_detections: sortedArray[1]?.detections || 0
          })
        })

        let sumVBUs = dataArr.reduce((sumObj, currentObj) => {
          return {
            detections: (sumObj.detections || 0) + (currentObj.detections || 0),
            assets: (sumObj.assets || 0) + (currentObj.assets || 0),
            prev_month_detections: (sumObj.prev_month_detections || 0) + (currentObj.prev_month_detections || 0),
          }
        }, 0)

        return {
          detections: Number.isInteger(sumVBUs.detections / dataArr.length) ?
            (sumVBUs.detections / dataArr.length) : (sumVBUs.detections / dataArr.length).toFixed(2),
          assets: Number.isInteger(sumVBUs.assets / dataArr.length) ?
            (sumVBUs.assets / dataArr.length) : (sumVBUs.assets / dataArr.length).toFixed(2),
          prev_month_detections: Number.isInteger(sumVBUs.prev_month_detections / dataArr.length) ?
            (sumVBUs.prev_month_detections / dataArr.length) : (sumVBUs.prev_month_detections / dataArr.length).toFixed(2),
        };
      } else {
        return {}
      }
    },
    isDetectionHigher() {
      return this.orgData.prevMonthData >
      this.orgData.prev_of_prev_month_detections
        ? true
        : this.orgData.prevMonthData ==
        this.orgData.prev_of_prev_month_detections
          ? null
          : false;
    },
  }
};
</script>

<style lang="scss" scoped>
.card {
  &--title {
    @apply transition-colors duration-300 relative;

    h4 {
      @apply relative pl-2;

      &::before {
        @apply w-2 h-2 rounded-full bg-white absolute top-2 -left-2 opacity-20;

        content: '';
      }

      // border-bottom: 1px rgba(255, 255, 255, 0.467) dashed;

      &.group::before {
        @apply opacity-100;
        background-color: rgb(90, 192, 30);
      }

      &.portfolio::before {
        @apply opacity-100 bg-blue-500;
      }
    }
  }

  &:hover {
    .card--title {
      background-color: rgba(52, 52, 62, 1);

      .icon {
        @apply text-gray-400;
      }
    }
  }
}
</style>

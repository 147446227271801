<template>
  <router-link
    class="card flex flex-col cursor-pointer"
    :to="orgData.users_count ? `/azure/${org.org_id}` : '#'"
  >
    <div
      class="flex justify-between h-12 items-center bg-bgLighter border-b border-gray-700 px-6 py-3 text-gray-300 card--title"
      :class="orgData.users_count && 'clickable'"
    >
      <div>
        <img src="@/assets/azure_logo.png" class="h-5 inline-block" />
        <BaseTooltip
          class="ml-2 inline-block"
          :info="'Multi-factor Authentication'"
        >
          <font-awesome-icon
            class="text-gray-500"
            :icon="['fa', 'info-circle']"
          />
        </BaseTooltip>
      </div>
      <font-awesome-icon
        v-if="orgData.users_count"
        class="text-gray-700 text-xl transform -rotate-45 icon transition-colors"
        :icon="['fa', 'arrow-right']"
      />
    </div>
    <div class="w-full h-full flex items-center" v-if="(orgData.users_count && !isAggregateMode) || isAggregateMode">
      <div class="flex flex-col items-center px-6 py-4 w-full">
        <div class="flex items-center">
          <font-awesome-icon
            class="text-xl mr-2 text-red-500"
            :icon="['fa', 'fingerprint']"
          />
          <h2 class="text-4xl" v-if="isAggregateMode && org.type != 'VBU'">
            <!-- if dividing 0/0 means there are users, but all are incapable therefore mfa enabled is 100% -->
            {{
              Math.round(
                (orgDataAggregate.mfa_count /
                  (orgDataAggregate.users_count - orgDataAggregate.mfa_incapable)) *
                100
              ) || 100
            }}%
          </h2>
          <h2 class="text-4xl" v-else>
            <!-- if dividing 0/0 means there are users, but all are incapable therefore mfa enabled is 100% -->
            {{
              Math.round(
                (orgData.mfa_count /
                  (orgData.users_count - orgData.mfa_incapable)) *
                  100
              ) || 100
            }}%
          </h2>
        </div>
        <p class="text-sm text-gray-500 text-center">
          percentage of users<br />
          with MFA enabled
        </p>
      </div>
      <div class="self-stretch border-l border-gray-700"></div>
      <div class="flex flex-col">
        <div class="p-3 border-b border-gray-700">
          <div
            class="flex flex-col items-center justify-center h-full px-6 w-full"
          >
            <div class="flex items-center">
              <font-awesome-icon
                class="mr-2 text-indigo-400"
                :icon="['fa', 'user-friends']"
              />
              <h4 v-if="isAggregateMode && org.type != 'VBU'">
                {{ orgDataAggregate.users_count }}
              </h4>
              <h4 v-else>
                {{ orgData.users_count }}
              </h4>
            </div>
          </div>
          <p class="text-xs text-gray-500 text-center">users count</p>
        </div>
        <div class="p-3">
          <div
            class="flex flex-col items-center justify-center h-full px-6 w-full"
          >
            <div class="flex items-center">
              <font-awesome-icon
                class="mr-2 text-yellow-400"
                :icon="['fa', 'user-shield']"
              />
              <h4 v-if="isAggregateMode && org.type != 'VBU'">
                {{ orgDataAggregate.mfa_incapable }}
              </h4>
              <h4 v-else>
                {{ orgData.mfa_incapable }}
              </h4>
            </div>
          </div>
          <p class="text-xs text-gray-500 text-center">MFA incapable users</p>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-center h-full" v-else>
      <p class="py-8 opacity-50">No azure users found.</p>
    </div>
  </router-link>
</template>

<script>
import BaseTooltip from '@/components/Base/BaseTooltip';
import { formatBigNum } from '@/utils/utils';
import azure from "@/store/modules/azure";

export default {
  components: {
    BaseTooltip,
  },
  props: {
    org: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isAggregateMode() {
      return this.$store.state.structure.isAggregateMode;
    },
    orgData() {
      return this.$store.state.azure.data[this.org.org_id] || null;
    },
    orgDataAggregate() {
      if(this.org.VBUsSystems) {
        let azureList = this.org.VBUsSystems.azure;
        let sumVBUs = azureList.reduce((sumObj, currentObj) => {
          return {
            mfa_count: (sumObj.mfa_count || 0) + (currentObj.mfa_count || 0),
            mfa_incapable: (sumObj.mfa_incapable || 0) + (currentObj.mfa_incapable || 0),
            users_count: (sumObj.users_count || 0) + (currentObj.users_count || 0)
          }
        }, 0)

        return {
          mfa_count: Number.isInteger(sumVBUs.mfa_count / azureList.length) ?
            (sumVBUs.mfa_count / azureList.length) : (sumVBUs.mfa_count / azureList.length).toFixed(2),
          mfa_incapable: Number.isInteger(sumVBUs.mfa_incapable / azureList.length) ?
            (sumVBUs.mfa_incapable / azureList.length) : (sumVBUs.mfa_incapable / azureList.length).toFixed(2),
          users_count: Number.isInteger(sumVBUs.users_count / azureList.length) ?
            (sumVBUs.users_count / azureList.length) : (sumVBUs.users_count / azureList.length).toFixed(2),
        }
      } else {
        return {};
      }
    }
  },
  methods: {
    formatBigNum,
  }
};
</script>

<style lang="scss" scoped>
.card {
  &--title {
    @apply transition-colors duration-300 relative;
  }

  &:hover {
    .card--title.clickable {
      background-color: rgba(52, 52, 62, 1);

      .icon {
        @apply text-gray-400;
      }
    }
  }
}
</style>

<template>
  <router-link
    class="card flex flex-col"
    :class="{'cursor-auto': !breachesData}"
    :to="breachesData ? `/spycloud/${org.org_id}` : '#'"
  >
    <div
      class="flex justify-between h-12 items-center bg-bgLighter border-b border-gray-700 px-6 py-3 text-gray-300 card--title"
      :class="breachesData && 'clickable'"
    >
      <div>
        <img
          src="@/assets/SpyCloud-logotype-white.png"
          class="h-6 inline-block"
        />
        <BaseTooltip
          class="ml-2 inline-block"
          :info="'Account Takeover Protection'"
        >
          <font-awesome-icon
            class="text-gray-500"
            :icon="['fa', 'info-circle']"
          />
        </BaseTooltip>
      </div>
      <font-awesome-icon
        v-if="breachesData"
        class="text-gray-700 text-xl transform -rotate-45 icon transition-colors"
        :icon="['fa', 'arrow-right']"
      />
    </div>
    <div
      class="w-full h-full flex items-center border-r border-gray-700"
      v-if="(breachesData && !isAggregateMode) || isAggregateMode"
    >
      <div class="flex flex-col items-center px-6 py-4 w-full">
        <div class="flex items-center" v-if="isAggregateMode && org.type != 'VBU'">
          <font-awesome-icon
            class="text-xl mr-2"
            :class="
              orgDataAggregate.prev_breaches_num > orgDataAggregate.prev_of_prev_breaches_num
                ? 'text-red-500'
                : 'text-green-500'
            "
            :icon="[
              'fa',
              `arrow-alt-circle-${
                orgDataAggregate.prev_breaches_num > orgDataAggregate.prev_of_prev_breaches_num
                  ? 'up'
                  : 'down'
              }`,
            ]"
          />
          <h2 class="text-4xl">
            {{ orgDataAggregate.prev_breaches_num }}
          </h2>
        </div>
        <div class="flex items-center" v-else>
          <font-awesome-icon
            class="text-xl mr-2"
            :class="
              orgData.prev_breaches_num > orgData.prev_of_prev_breaches_num
                ? 'text-red-500'
                : 'text-green-500'
            "
            :icon="[
              'fa',
              `arrow-alt-circle-${
                orgData.prev_breaches_num > orgData.prev_of_prev_breaches_num
                  ? 'up'
                  : 'down'
              }`,
            ]"
          />
          <h2 class="text-4xl">
            {{ orgData.prev_breaches_num }}
          </h2>
        </div>
        <p class="text-sm text-gray-500 text-center">
          last month critical compromises
        </p>
      </div>
      <div class="self-stretch border-l border-gray-700"></div>
      <div class="flex flex-col items-center justify-center h-full px-6 w-full">
        <div class="flex items-center" v-if="isAggregateMode && org.type != 'VBU'">
          <font-awesome-icon
            class="text-xl mr-2 text-yellow-500"
            :icon="[
              'fa',
              `${
                orgDataAggregate.curr_breaches_num > 0
                  ? 'exclamation-triangle'
                  : 'shield-alt'
              }`,
            ]"
          />
          <h2 class="text-4xl">{{ orgDataAggregate.curr_breaches_num }}</h2>
        </div>
        <div class="flex items-center" v-else>
          <font-awesome-icon
            class="text-xl mr-2 text-yellow-500"
            :icon="[
              'fa',
              `${
                orgData.curr_breaches_num > 0
                  ? 'exclamation-triangle'
                  : 'shield-alt'
              }`,
            ]"
          />
          <h2 class="text-4xl">{{ orgData.curr_breaches_num }}</h2>
        </div>
        <p class="text-sm text-gray-500 text-center">
          this month <br />
          compromises
        </p>
      </div>
    </div>
    <div class="flex items-center justify-center h-full" v-else>
      <p class="py-8 opacity-50">No users found.</p>
    </div>
  </router-link>
</template>

<script>
import BaseTooltip from '@/components/Base/BaseTooltip';

export default {
  components: {
    BaseTooltip,
  },
  props: {
    org: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isAggregateMode() {
      return this.$store.state.structure.isAggregateMode;
    },
    orgData() {
      let dataObj = {
        curr_breaches_num: 0,
        prev_breaches_num: 0,
        prev_of_prev_breaches_num: 0,
      };

      if (this.breachesData) {
        // Aggregates data in case there's more than one email_org<=>org_id connections
        for (const key in dataObj) {
          dataObj[key] = this.breachesData.reduce((acc, curr) => {
            return (acc += curr[key]);
          }, 0);
        }
        dataObj = {
          ...dataObj,
        };
      }

      return dataObj;
    },
    orgDataAggregate() {
      let spycloudList = this.org.VBUsSystems.spycloud;
      // console.log("spycloudList", spycloudList)

      if(this.org.VBUsSystems && spycloudList.length) {
        let sumVBUs = spycloudList.reduce((sumObj, currentObj) => {
          return {
            curr_breaches_num: (sumObj.curr_breaches_num || 0) + Number(currentObj[0]?.curr_breaches_num || 0),
            prev_breaches_num: (sumObj.prev_breaches_num || 0) + Number(currentObj[0]?.prev_breaches_num || 0),
            prev_of_prev_breaches_num: (sumObj.prev_of_prev_breaches_num || 0) + Number(currentObj[0]?.prev_of_prev_breaches_num || 0)
          }
        }, 0)

        return {
          curr_breaches_num: Number.isInteger(sumVBUs.curr_breaches_num / spycloudList.length) ?
            (sumVBUs.curr_breaches_num / spycloudList.length) : (sumVBUs.curr_breaches_num / spycloudList.length).toFixed(2),
          prev_breaches_num: Number.isInteger(sumVBUs.prev_breaches_num / spycloudList.length) ?
            (sumVBUs.prev_breaches_num / spycloudList.length) : (sumVBUs.prev_breaches_num / spycloudList.length).toFixed(2),
          prev_of_prev_breaches_num: Number.isInteger(sumVBUs.prev_of_prev_breaches_num / spycloudList.length) ?
            (sumVBUs.prev_of_prev_breaches_num / spycloudList.length) : (sumVBUs.prev_of_prev_breaches_num / spycloudList.length).toFixed(2),
        };
      } else {
        return {}
      }
    },
    breachesData() {
      return this.$store.state.spycloud.breaches[this.org.org_id] || null;
    },
  }
};
</script>

<style lang="scss" scoped>
.card {
  &--title {
    @apply transition-colors duration-300 relative;
  }

  &:hover {
    .card--title.clickable {
      background-color: rgba(52, 52, 62, 1);

      .icon {
        @apply text-gray-400;
      }
    }
  }
}
</style>

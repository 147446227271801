<template>
  <div>
    <BackButton />

    <div v-if="!$apollo.loading">
      <div class="flex justify-between">
        <div>
          <h4 class="text-lg mr-auto">{{ org.name }}</h4>
          <p class="mb-6">Completion status for the 6 most recent campaigns</p>
        </div>
        <div class="text-center my-2">
          <img src="@/assets/knowbe4-logo.png" class="h-6 inline-block" />
        </div>
      </div>
      <div class="relative py-4">
        <div
          v-if="!campaigns.length"
          class="bg-green-900 flex items-center justify-center inset-0 blur-drop text-center rounded-md absolute z-10"
        >
          <p>No campaigns found.</p>
        </div>
        <KnowBe4Chart :chartData="chartData" />
      </div>
    </div>
    <div class="w-10 h-10 mx-auto my-4" v-else>
      <SpinnerBase />
    </div>
  </div>
</template>

<script>
import { gql } from 'graphql-tag';
import SpinnerBase from '@/components/Base/SpinnerBase';
import KnowBe4Chart from '@/components/KnowBe4Chart';
import {} from '@/utils/time';
import BackButton from '@/components/Base/BackButton';

const query = gql`
  query knowbe4($orgId: Int!) {
    _organisations(where: { org_id: { _eq: $orgId } }) {
      knowbe4_user_aggregate {
        aggregate {
          count
        }
      }
      knowbe4_user {
        email
        enrollments {
          campaign_name
          start_date
          score
          status
          time_spent
          campaign(where: { start_date: { _gt: "2021-08-01" } }) {
            campaign_id
            start_date
            name
            completion_percentage
            status
          }
        }
      }
    }
  }
`;

export default {
  components: {
    BackButton,
    SpinnerBase,
    KnowBe4Chart,
  },
  apollo: {
    _organisations: {
      query,
      variables() {
        return {
          orgId: this.id,
        };
      },
    },
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    org() {
      return this.$store.getters['structure/GET_ORGANISATION'](this.id);
    },
    campaigns() {
      let campaignsArr = [];
      if (!this._organisations) return [];

      this._organisations.forEach((el) => {
        el.knowbe4_user.forEach((user) => {
          user.enrollments.forEach((enrollment) => {
            enrollment.campaign.forEach((campaign) => {
              let campaingIndex = campaignsArr.findIndex(
                (el) => el.campaign_id === campaign.campaign_id
              );
              if (campaingIndex === -1) {
                campaignsArr.push(campaign);
              }
            });
          });
        });
      });

      campaignsArr.sort(
        (firstEl, secondEl) =>
          new Date(secondEl.start_date).getTime() -
          new Date(firstEl.start_date).getTime()
      );

      return campaignsArr.splice(0, 6);
    },
    emails() {
      return this._organisations.flatMap((el) => el.email);
    },
    chartData() {
      const data = {
        labels: [],
        datasets: [
          {
            label: 'Not Started',
            data: [],
            backgroundColor: 'rgba(245, 158, 11, 0.7)',
            // borderWidth: 1,
            // borderColor: 'rgba(245, 158, 11, 1)',
          },
          {
            label: 'In Progress',
            data: [],
            backgroundColor: 'rgba(59, 130, 246, 0.7)',
            // borderWidth: 1,
            // borderColor: 'rgba(59, 130, 246, 1)',
          },
          {
            label: 'Completed',
            data: [],
            backgroundColor: 'rgba(139, 92, 246, 0.7)',
            // borderWidth: 1,
            // borderColor: 'rgba(139, 92, 246, 1)',
          },
        ],
      };

      // console.log("campaigns", this.campaigns)
      this.campaigns.reverse().forEach((campaign, i) => {

        data.labels.push(campaign.name);
        data.datasets.forEach((el) => el.data.push(0));

        if (campaign.status == 'Completed') {
          data.datasets[1].data[i] = campaign.completion_percentage;
        }

        if (campaign.status == 'In Progress') {
          const enrollments = this.enrollments(campaign.name);
          // console.log("enrolments", enrollments)
          const completed = enrollments.filter((el) => el.status == 'Passed');
          data.datasets[2].data[i] = Math.round(
            (completed.length / enrollments.length) * 100
          );
          const notStarted = enrollments.filter(
            (el) => el.status == 'Not Started'
          );
          data.datasets[0].data[i] = Math.round(
            (notStarted.length / enrollments.length) * 100
          );
          const inProgress = enrollments.filter(
            (el) => el.status == 'In Progress'
          );
          data.datasets[1].data[i] = Math.round(
            (inProgress.length / enrollments.length) * 100
          );
        }
      });

      // console.log("data", data)

      return data;
    },
  },
  methods: {
    enrollments(campaignName) {
      return this._organisations.flatMap((el) => {
        return el.knowbe4_user.flatMap((user) => {
          return user.enrollments.filter(
            (enrollment) => enrollment.campaign_name == campaignName
          );
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.blur-drop {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
}
</style>

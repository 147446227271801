<template>
  <!-- COLUMN Netsparker -->
  <div class="w-full">
    <HeatmapHeader
      :infoLeft="'Operational: Number of account compromises in the last month'"
      :infoRight="'Deployment: Red = not deployed, green = deployed'"
    >
      <img src="@/assets/SpyCloud-logotype-white.png" class="h-4 mt-2.5" />
    </HeatmapHeader>
    <div class="grid grid-cols-2 gap-1">
      <!-- left column -->
      <div class="w-full">
        <div
          v-for="(org, i) in flatStructure"
          :key="i"
          class="heatmap-item"
          :class="i == hoveredRow && 'border border-gray-200'"
          :style="`background-color: rgba(${colorsNumb[i].operational.color}, ${
            i == hoveredRow ? '1' : '0.8'
          })`"
          @mouseover="$emit('onRowHover', i)"
          @mouseleave="$emit('onRowHover', null)"
          @click="heatmapItemClicked(i, colorsNumb[i], org)"
        >
          <p v-show="showValue || i == hoveredRow">
            {{
              colorsNumb[i].operational.value >= 0
                ? colorsNumb[i].operational.value
                : '--'
            }}
          </p>
        </div>
      </div>
      <div class="w-full">
        <div
          v-for="(org, i) in flatStructure"
          :key="i"
          class="heatmap-item"
          :class="i == hoveredRow && 'border border-gray-200'"
          :style="`background-color: rgba(${colorsNumb[i].deployment.color}, ${
            i == hoveredRow ? '1' : '0.8'
          })`"
          @mouseover="$emit('onRowHover', i)"
          @mouseleave="$emit('onRowHover', null)"
          @click="heatmapItemClicked(i, colorsNumb[i], org)"
        >
          <p v-show="showValue || i == hoveredRow">
            {{ colorsNumb[i].deployment.value }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeatmapHeader from '@/components/HeatmapHeader';

import { normaliseValue, normaliseColour } from '@/utils/utils';

export default {
  components: {
    HeatmapHeader,
  },
  props: {
    flatStructure: {
      type: Array,
      required: true,
    },
    hoveredRow: {
      type: [Number, null],
      default: null,
    },
    clickedRow: {
      type: [Number, null],
      default: null,
    },
    showValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colorsNumb() {
      return this.flatStructure.map((el) => {
        const heatmapValues = {
          operational: { color: NaN, value: '-' },
          deployment: { color: NaN, value: '-' },
        };

        const isActive = this.$store.state.spycloud.isActive[el.org_id];
        // DEPLOYMENT VALUE
        heatmapValues.deployment = {
          value: isActive ? 'active' : 'inactive',
          color: this.normaliseColour(
            this.normaliseValue(isActive ? 1 : 0, 0, 1),
            true,
            this.colorBlindMode
          ),
        };

        if (isActive) {
          const lastMonthBreaches =
            this.$store.state.spycloud.breaches?.[el.org_id]?.[0]
              ?.prev_breaches_num || 0;
          // OPERATIONAL VALUE
          heatmapValues.operational = {
            value: lastMonthBreaches,
            color: this.normaliseColour(
              this.normaliseValue(lastMonthBreaches, 0, 5),
              false,
              this.colorBlindMode
            ),
          };
        }

        return heatmapValues;
      });
    },
    colorBlindMode() {
      return this.$store.state.ui.colorblindMode;
    },
  },
  methods: {
    normaliseValue,
    normaliseColour,
    heatmapItemClicked(i, color, org) {
      this.$emit('onRowClick', i)

      this.$store.commit('structure/SET_SECURITY_SUMMARY_MODAL_INFO', {
        system: 'spycloud',
        index: i,
        color: color,
        org: org
      })

      this.$vfm.show('security-summary-modal');
    }
  },
  watch: {
    clickedRow(clickedRowIndex) {
      this.$store.commit('structure/SET_SECURITY_SUMMARY_MODAL_INFO', {
        system: 'spycloud',
        index: clickedRowIndex,
        color: this.colorsNumb[clickedRowIndex],
        org: this.flatStructure[clickedRowIndex]
      })
    }
  }
};
</script>

<style lang="scss" scoped></style>

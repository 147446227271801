<template>
  <div>
    <BackButton />

    <div v-if="!$apollo.queries._organisations.loading">
      <div class="flex justify-between">
        <div>
          <h4 class="text-lg mr-auto">{{ org.name }}</h4>
          <p class="mb-6">
            Number of critical and high account compromises over the preceding 6 months
          </p>
        </div>
        <div class="text-center my-2">
          <img
            src="@/assets/SpyCloud-logotype-white.png"
            class="h-8 inline-block"
          />
        </div>
      </div>

      <div v-if="!emails.length" class="bg-bgLight py-8 text-center rounded-md">
        <p>There's no emails coupled with this organisation.</p>
      </div>

      <div class="relative py-4" v-else>
        <div
          v-if="!chartData.labels.length"
          class="bg-green-900 flex items-center justify-center inset-0 blur-drop text-center rounded-md absolute z-10"
        >
          <p>No detections found.</p>
        </div>
        <SpyCloudChart :chartData="chartData" />
      </div>
    </div>
    <div class="w-10 h-10 mx-auto my-4" v-else>
      <SpinnerBase />
    </div>
  </div>
</template>

<script>
import { gql } from 'graphql-tag';
import SpinnerBase from '@/components/Base/SpinnerBase';
import SpyCloudChart from '@/components/SpyCloudChart';
import {} from '@/utils/time';
import BackButton from '@/components/Base/BackButton';

const query = gql`
  query getSpyCloud($sixMonthsAgo: timestamp!, $orgId: Int!) {
    _organisations(where: { org_id: { _eq: $orgId } }) {
      org_id
      emails {
        spycloud_breach(
          order_by: { publish_date: desc }
          where: { publish_date: { _gt: $sixMonthsAgo } }
        ) {
          publish_date
          infected_time
          severity
        }
      }
    }
  }
`;

export default {
  components: {
    BackButton,
    SpinnerBase,
    SpyCloudChart,
  },
  apollo: {
    _organisations: {
      query,
      variables() {
        return {
          orgId: this.id,
          sixMonthsAgo: this.sixMonthsAgo,
        };
      },
    },
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    org() {
      return this.$store.getters['structure/GET_ORGANISATION'](this.id);
    },
    sixMonthsAgo() {
      const date = new Date();
      date.setMonth(date.getMonth() - 6);

      return new Date(date.getFullYear(), date.getMonth(), 1);
    },
    breaches() {
      console.log("this._organisations spycloud detail page", this._organisations)
      return this._organisations
        .flatMap((el) => el.emails.flatMap((mail) => mail.spycloud_breach))
        .sort(
          (firstEl, secondEl) =>
            new Date(firstEl.infected_time).getTime() -
            new Date(secondEl.infected_time).getTime()
        );
    },
    emails() {
      return this._organisations.flatMap((el) => el.emails);
    },
    chartData() {
      const monthsArray = Array.from({ length: 6 }, (_, i) => {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth() - i, 1);
      }).reverse();

      const data = {
        labels: [],
        datasets: [
          {
            data: [],
            fill: true,
            label: 'Critical',
            backgroundColor: 'rgba(239, 68, 68, 0.57)',
            //borderColor: 'rgb(185, 28, 28)'
          },
          {
            data: [],
            fill: true,
            label: 'High',
            backgroundColor: 'rgba(245, 158, 11, 0.7)',
            //borderColor: 'rgb(194, 65, 12)'
          },
        ],
      };

      monthsArray.forEach((month) => {
        data.labels.push(
          month.toLocaleString('en-GB', { month: 'long' }).toUpperCase()
        );
        data.datasets[0].data.push(
          this.breaches.filter((breach) => {
            return (
              new Date(breach.infected_time).getMonth() === month.getMonth() &&
              breach.severity === 25
            );
          }).length
        );
        data.datasets[1].data.push(
          this.breaches.filter((breach) => {
            return (
              new Date(breach.infected_time).getMonth() === month.getMonth() &&
              breach.severity === 20
            );
          }).length
        );
      });

      return data;
    },
  },
  methods: {
    websiteScans(websiteId) {
      return this._netsparker_details.filter(
        (scan) => scan.website_id == websiteId
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.blur-drop {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
}
</style>

<template>
  <!-- COLUMN Netsparker -->
  <div class="w-full">
    <HeatmapHeader
      :infoLeft="'Operational: Red = Risk level per asset > 0, green = no risk'"
      :infoRight="'Deployment: Red = not deployed, green = deployed'"
    >
      <img src="@/assets/rapid7_logo.svg" class="h-3 mt-3" />
    </HeatmapHeader>
    <div class="grid grid-cols-2 gap-1">
      <!-- left column -->
      <div class="w-full">
        <div
          v-for="(org, i) in flatStructure"
          :key="i"
          class="heatmap-item"
          :class="i == hoveredRow && 'border border-gray-200'"
          :style="`background-color: rgba(${colorsNumb[i].operational.color}, ${
            i == hoveredRow ? '1' : '0.8'
          })`"
          @mouseover="$emit('onRowHover', i)"
          @mouseleave="$emit('onRowHover', null)"
          @click="heatmapItemClicked(i, colorsNumb[i], org)"
        >
          <p v-show="showValue || i == hoveredRow">
            {{
              colorsNumb[i].operational.value
                ? colorsNumb[i].operational.value
                : '--'
            }}
          </p>
        </div>
      </div>
      <div class="w-full">
        <div
          v-for="(org, i) in flatStructure"
          :key="i"
          class="heatmap-item"
          :class="i == hoveredRow && 'border border-gray-200'"
          :style="`background-color: rgba(${colorsNumb[i].deployment.color}, ${
            i == hoveredRow ? '1' : '0.8'
          })`"
          @mouseover="$emit('onRowHover', i)"
          @mouseleave="$emit('onRowHover', null)"
          @click="heatmapItemClicked(i, colorsNumb[i], org)"
        >
          <p v-show="showValue || i == hoveredRow">
            {{ colorsNumb[i].deployment.value }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeatmapHeader from '@/components/HeatmapHeader';

import { normaliseValue, normaliseColour } from '@/utils/utils';
import { formatBigNum } from '@/utils/utils';

export default {
  components: {
    HeatmapHeader,
  },
  props: {
    flatStructure: {
      type: Array,
      required: true,
    },
    hoveredRow: {
      type: [Number, null],
      default: null,
    },
    clickedRow: {
      type: [Number, null],
      default: null,
    },
    showValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colorsNumb() {
      return this.flatStructure.map((el) => {
        const heatmapValues = {
          operational: { color: NaN, value: '-' },
          deployment: { color: NaN, value: '-' },
        };

        let dataEl = this.$store.state.rapid7.data[el.org_id] || false;
        if (dataEl.assets === 0) {
          dataEl = false;
        }
        // DEPLOYMENT VALUE
        heatmapValues.deployment = {
          value: Boolean(dataEl),
          color: this.normaliseColour(
            this.normaliseValue(dataEl ? 1 : 0, 0, 1),
            true,
            this.colorBlindMode
          ),
        };

        if (dataEl) {
          const riskScore = dataEl.risk_score;
          const assets = dataEl.assets;

          let oprValue = assets > 0 ? Math.round(riskScore / assets) : 0;

          let oprValueNormalise;
          switch (true) {
            case oprValue <= 20000:
              oprValueNormalise = 0;
              break;
            case oprValue > 20000 && oprValue <= 40000:
              oprValueNormalise = 0.1;
              break;
            case oprValue > 40000 && oprValue <= 80000:
              oprValueNormalise = 0.4;
              break;
            case oprValue > 80000 && oprValue <= 150000:
              oprValueNormalise = 0.8;
              break;
            case oprValue > 150000:
              oprValueNormalise = 1;
              break;
          }

          // OPERATIONAL VALUE
          heatmapValues.operational = {
            value: formatBigNum(oprValue),
            color: this.normaliseColour(
              oprValueNormalise,
              false,
              this.colorBlindMode
            ),
          };
        }

        return heatmapValues;
      });
    },
    colorBlindMode() {
      return this.$store.state.ui.colorblindMode;
    },
  },
  methods: {
    normaliseValue,
    normaliseColour,
    formatBigNum,
    heatmapItemClicked(i, color, org) {
      this.$emit('onRowClick', i)

      this.$store.commit('structure/SET_SECURITY_SUMMARY_MODAL_INFO', {
        system: 'rapid7',
        index: i,
        color: color,
        org: org
      })

      this.$vfm.show('security-summary-modal');
    }
  },
  watch: {
    clickedRow(clickedRowIndex) {
      this.$store.commit('structure/SET_SECURITY_SUMMARY_MODAL_INFO', {
        system: 'rapid7',
        index: clickedRowIndex,
        color: this.colorsNumb[clickedRowIndex],
        org: this.flatStructure[clickedRowIndex]
      })
    }
  }
};
</script>

<style lang="scss" scoped></style>

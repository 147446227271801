import { apolloClient as apollo } from '@/apollo';
import { currentMonth } from '@/utils/time';

const state = {
  data: {},
};

const getters = {};

const mutations = {
  RESET_STORE: (store) => {
    Object.assign(store, { data: [] });
  },
  SET_DATA: (state, { data }) => {
    data.forEach((dataEl) => {
      if (!dataEl.rapid7?.group_data?.length) return;

      const dataElementPretty = {
        assets: dataEl.rapid7.group_data[0].assets,
        risk_score: dataEl.rapid7.group_data[0].risk_score,
      };

      state.data[dataEl.org_id] = dataElementPretty;
    });
  },
};

const actions = {
  getAllData({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { _organisations },
        } = await apollo.query({
          query: require('@/graphql/rapid7/get_data.gql'),
          variables: {
            month: currentMonth,
          },
        });

        commit('SET_DATA', { data: _organisations });

        resolve();
      } catch (e) {
        console.log(e);
        reject();
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

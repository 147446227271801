import { apolloClient as apollo } from '@/apollo';

const state = {
  data: [],
  websitesIds: {},
};

const getters = {};

const mutations = {
  RESET_STORE: (store) => {
    Object.assign(store, { data: [] });
  },
  SET_ORG_WEBSITES: (state, { orgWebsites }) => {
    orgWebsites.forEach((orgWebsiteEl) => {
      if (
        !Object.prototype.hasOwnProperty.call(
          state.websitesIds,
          orgWebsiteEl.org_id
        )
      ) {
        state.websitesIds[orgWebsiteEl.org_id] = [orgWebsiteEl];
      } else {
        state.websitesIds[orgWebsiteEl.org_id].push(orgWebsiteEl);
      }
    });
  },
};

const actions = {
  getOrgWebsites({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { _netsparker_sites },
        } = await apollo.query({
          query: require('@/graphql/netsparker/get_org_websites.gql'),
        });

        commit('SET_ORG_WEBSITES', { orgWebsites: _netsparker_sites });

        resolve();
      } catch (e) {
        console.log(e);
        reject();
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

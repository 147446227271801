<template>
  <div class="sidebar">
    <div class="flex mb-10 -ml-6 -mt-6">
      <SearchBox />
      <ExpandCollapseButton />
    </div>

    <div class="flex -mt-4 mb-8 -ml-6 justify-on start">
      <div class="flex items-center">
        <div class="mt-0.5 mr-1 w-2 h-2 rounded-full bg-gray-400"></div>
        <p class="text-xs">- VBU</p>
      </div>
      <div class="flex items-center mx-6">
        <div class="mt-0.5 mr-1 w-2 h-2 rounded-full bg-green-500"></div>
        <p class="text-xs">- Group</p>
      </div>
      <div class="flex items-center">
        <div class="mt-0.5 mr-1 w-2 h-2 rounded-full bg-blue-500"></div>
        <p class="text-xs">- Portfolio</p>
      </div>
      <div class="flex items-center mx-6">
        <div class="mt-0.5 mr-1 w-2 h-2 rounded-full bg-yellow-600"></div>
        <p class="text-xs">- Sub-Portfolio</p>
      </div>
    </div>
    <HierarchyTree
      v-for="node in hierarchy"
      :key="node.org_id"
      :node="node"
      @close="$emit('close')"
      @showAll="showAllHandler"
    />
  </div>
</template>

<script>
import HierarchyTree from '@/components/HierarchyTree';
import SearchBox from '@/components/SearchBox';
import ExpandCollapseButton from "@/components/ExpandCollapseButton";

export default {
  name: 'Dashboard',
  components: {
    HierarchyTree,
    SearchBox,
    ExpandCollapseButton
  },
  computed: {
    hierarchy() {
      return this.$store.state.structure.hierarchy;
    },
  },
  methods: {
    showAllHandler() {
      this.$store.commit('structure/SELECT_HIERARCHY', {
        hierarchy: this.hierarchy,
      });

      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  min-width: 28vw;
}
</style>

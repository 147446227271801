import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { apolloProvider } from './apollo';
import './assets/tailwind.css';
import './assets/main.scss';
import VueObserveVisibility from 'vue3-observe-visibility';
import { vfmPlugin } from "vue-final-modal";

// vue
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faLock,
  faArrowRight,
  faArrowLeft,
  faArrowAltCircleUp,
  faArrowAltCircleDown,
  faSearch,
  faServer,
  faChevronDown,
  faFilter,
  faExternalLinkAlt,
  faTimes,
  faCalendarDay,
  faCheckCircle,
  faTimesCircle,
  faExclamationCircle,
  faLaptopCode,
  faExclamationTriangle,
  faInfoCircle,
  faUserShield,
  faShieldAlt,
  faMouse,
  faCertificate,
  faShieldVirus,
  faFingerprint,
  faUserClock,
  faBusinessTime,
  faUserFriends,
} from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faLock,
  faUser,
  faArrowRight,
  faArrowLeft,
  faArrowAltCircleUp,
  faArrowAltCircleDown,
  faSearch,
  faServer,
  faChevronDown,
  faFilter,
  faExternalLinkAlt,
  faTimes,
  faCalendarDay,
  faCheckCircle,
  faTimesCircle,
  faExclamationCircle,
  faLaptopCode,
  faExclamationTriangle,
  faInfoCircle,
  faUserShield,
  faShieldAlt,
  faMouse,
  faCertificate,
  faShieldVirus,
  faFingerprint,
  faUserClock,
  faBusinessTime,
  faUserFriends
);

const vueApp = createApp(App);

vueApp.config.globalProperties.$dbEndpoint = process.env.VUE_APP_API_URL
  ? 'demo'
  : '';
vueApp.use(store);
vueApp.use(router);
vueApp.use(apolloProvider);
vueApp.use(VueObserveVisibility);
vueApp.use(vfmPlugin);

vueApp.component('font-awesome-icon', FontAwesomeIcon);

vueApp.mount('#app');

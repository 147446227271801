<template>
  <div>
    <bar-chart :chartData="chartData" :options="options" />
    <!-- <pre>
     {{ JSON.stringify(scans, null, 2) }}
    </pre> -->
  </div>
</template>

<script>
import { BarChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
  props: {
    chartData: {
      type: Object,
    },
    scans: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    BarChart,
  },
  setup(props) {
    // const chartData = {
    //   labels: [],
    //   datasets: [
    //     {
    //       label: 'Spam',
    //       data: [],
    //       backgroundColor: 'rgba(139, 92, 246, 0.7)',
    //     },
    //     {
    //       label: 'Phish',
    //       data: [],
    //       backgroundColor: 'rgba(245, 158, 11, 0.7)',
    //     },
    //     {
    //       label: 'Malware',
    //       data: [],
    //       backgroundColor: 'rgba(239, 68, 68, 0.57',
    //     },
    //   ],
    // };

    // props.scans.forEach((scan) => {
    //   // const date = new Date(scan.scan_date).toISOString().split('T')[0];
    //   const date = new Date(scan.scan_date).toLocaleDateString('en-GB');
    //   chartData.labels.push(date);
    //   chartData.datasets[0].data.push(scan.info_count);
    //   chartData.datasets[1].data.push(scan.best_practice_count);
    //   chartData.datasets[2].data.push(scan.low_count);
    //   chartData.datasets[3].data.push(scan.medium_count);
    //   chartData.datasets[4].data.push(scan.high_count);
    //   chartData.datasets[5].data.push(scan.critical_count);
    // });

    const options = {
      plugins: {
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            boxWidth: 9,
            boxHeight: 9,
            usePointStyle: true,
            color: 'rgba(255,255,255, 0.8)',
            padding: 25,
          },
        },
      },
      scales: {
        y: {
          stacked: true,
          grid: {
            color: 'rgba(255, 255, 255, .1)',
          },
          ticks: {
            color: 'rgba(255, 255, 255, .65)',
            precision: 0,
            padding: 8,
          },
          title: {
            display: true,
            color: 'rgba(255, 255, 255, .8)',
            text: 'Risk Score per Asset',
            padding: 8,
            font: {
              size: 13,
            },
          },
        },
        x: {
          stacked: true,
          grid: {
            color: 'rgba(255, 255, 255, .1)',
          },
          ticks: {
            color: 'rgba(255, 255, 255, .65)',
            padding: 10,
          },
        },
      },
      // legend: { display: true },
    };

    // return { options, chartData };
    return { options };
  },
};
</script>

<style lang="scss" scoped></style>
